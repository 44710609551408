@import url("https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
  width: 100%;
  min-height: 100vh;
}

body {
  font-family: "Signika", sans-serif;
  font-size: 1rem;
  background-color: #0d2334;
  color: #ffffff;
  background: url("../images/bg_color.webp") center center no-repeat;
  overflow-x: hidden;
  background-size: cover;
}

input:focus,
input[type="text"],
input[type="number"],
textarea {
  font-size: 16px !important;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  font-family: "Candara", Arial, sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  padding: 0 5rem;
  margin: 0 auto;
  max-width: 1600px;
}

@media only screen and (max-width: 90rem) {
  .container {
    padding: 0 2rem;
  }
}

p {
  font-weight: 300;
}

.sub_menu_body {
  position: fixed;
  right: -100%;
  z-index: 10000;
  transition: 0.5s ease all;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 1.5rem;

  &::before {
    content: "";
    position: absolute;
    background: linear-gradient(270deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 100%;
    right: -100%;
    transition: 0.5s ease all;
  }

  @media screen and (max-width: 48rem) {
    top: -100%;
    left: 0;
    right: 0;
    height: 86px;

    &::before {
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 1) 45%,
          rgba(0, 0, 0, 0) 100%);
      right: 0%;
      left: 0;
      top: 0;
      height: 100%;
    }

    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }

  &:hover {
    transition: 0.5s ease all;
    z-index: 100;

    &::before {
      right: 0;
      transition: 0.5s ease all;
    }

    span {
      opacity: 1;
      transition: 0.5s ease all;
    }

    span,
    img {
      animation: bounce-in 1s ease-out forwards;
    }
  }

  &.show {
    right: 0;
    opacity: 1;
    transition: 0.5s ease all;

    @media screen and (max-width: 48rem) {
      padding: 1rem;
      top: 0;
      left: 0;
    }

    li {
      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          animation: bounce-in 1s $i * 0.1s ease-out forwards;
        }
      }
    }
  }

  li {
    display: flex;
    justify-content: flex-end;

    &.active {
      a {
        color: red;
      }

      img {
        filter: brightness(1.25) drop-shadow(0px 0px 10px #fbb867);
        transition: 0.5s ease all;
        max-width: 2.5rem;
      }
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;

      @media screen and (max-width: 48rem) {
        margin-bottom: 0;
      }
    }
  }

  span {
    opacity: 0;

    @media screen and (max-width: 36rem) {
      display: none;
    }
  }

  a {
    transition: 0.5s ease all;
    display: flex;
    align-items: center;
    color: #fee283;
    font-weight: 700;

    img {
      max-width: 3rem;
      margin-left: 0.625rem;
      transition: 0.5s ease all;

      @media screen and (max-width: 36rem) {
        max-width: 2.25rem;
      }
    }

    &:hover {
      img {
        filter: brightness(1.25) drop-shadow(0px 0px 10px #fbb867);
        transition: 0.5s ease all;
        max-width: 2.5rem;
      }
    }
  }
}

.main_title {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
  padding: 0 2rem;

  @media screen and (max-width: 36rem) {
    max-width: 100%;

    &_mobile {
      position: relative;
      top: 10%;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: url("../images/main_title_bg.webp") center center no-repeat;
        background-size: contain;
        opacity: 0.7;
        width: 100%;
        transform: scale(2.8);
        height: 100%;
        z-index: -1;

        @media screen and (max-width: 22.5rem) {
          transform: scale(2.3);
        }
      }
    }
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2rem;
    font-weight: 300;
    padding-bottom: 0.625rem;

    @media screen and (max-width: 36rem) {
      font-weight: 500;
    }
  }

  &::before {
    content: "";
    position: absolute;
    background: url("../images/bg_title.webp") center center no-repeat;
    background-size: contain;
    width: 100%;
    left: 50%;
    transform: translateX(-50%) scaleY(1.3);
    height: 157px;
    z-index: -1;
    top: -1.5rem;

    @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
      display: none;
    }
  }
}

header {
  position: absolute;
  width: 100%;
  z-index: 1000;

  @media screen and (max-width: 64rem) {
    top: -1.5rem;

    .container {
      padding: 0;
    }
  }

  nav {
    display: flex;
    align-items: center;
    justify-self: start;

    @media screen and (max-width: 64rem) {
      position: absolute;
      width: 100%;
      height: 7rem;
      z-index: 100;
      overflow: hidden;

      &.navbar {
        li {
          &:not(:last-child) {
            transition: 0.5s ease all;
            margin-bottom: 0.5rem !important;
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        background: url("../images/menubar.webp") no-repeat center center;
        background-size: cover;
        width: 100%;
        height: 100%;
        z-index: 100;
        top: -0.625rem;

        @media screen and (max-width: 64rem) {
          background: url("../images/menubar_mobile.webp") center center no-repeat;
          background-size: cover;
          transform: scale(1.1);
          top: -0.5rem;
          height: 6.5rem;
        }
      }
    }
  }

  #logo {
    max-width: 12.5rem;
    height: auto;

    @media screen and (min-width: 48rem) and (max-width: 86rem) {
      max-width: 10rem;
    }

    .logo-pc {
      @media screen and (max-width: 64rem) {
        display: none;
      }
    }

    .logo-mb {
      display: none;

      @media screen and (max-width: 64rem) {
        display: block;
        max-width: 8rem;
        position: absolute;
        z-index: 999;
        left: 50%;
        top: 2.25rem;
        transform: translateX(-50%);
      }
    }
  }

  .menu_nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 7rem;
    left: 50%;
    margin-left: -0.5rem;
    transform: translateX(-50%);

    @media screen and (min-width: 48rem) and (max-width: 86rem) {
      position: relative;
      left: 0;
      transform: none;
    }

    &::before {
      content: "";
      position: absolute;
      background: url("../images/menubar.webp") no-repeat center center;
      background-size: contain;
      width: 100%;
      height: 100%;
      max-height: 7rem;
      max-width: 52.5rem;
      z-index: -1;
      margin-left: 1.5rem;

      @media screen and (max-width: 64rem) {
        background-size: cover;
        display: none;
      }
    }

    >li:not(:last-child) {
      margin-right: 2rem;

      @media screen and (max-width: 64rem) {
        transition: 0.5s ease all;
        margin-right: 0;
        margin-bottom: 1.5rem;
      }
    }

    .sub_menu_link,
    a {
      font-family: "Candara", Arial, sans-serif;
      display: inline-block;
      padding-top: 0.875rem;
      font-size: 1rem;
      left: -10px;
      color: #ffffff;
      font-weight: bold;
      text-shadow: 1px 1px 3px #000000;
      transition: 0.5s ease color;

      @media screen and (max-width: 64rem) {
        left: 0;
      }

      &:hover,
      &:active {
        color: #fdd54a;
      }
    }

    @media screen and (max-width: 64rem) {
      flex-direction: column;
      position: fixed;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      top: 0;
      left: -100%;
      opacity: 0;
      margin: 0;
      transition: 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
      transform: translateX(0);
      justify-content: flex-start;
      align-items: flex-start;

      &.active {
        position: fixed;
        height: 100vh;
        opacity: 1;
        overflow: hidden;
        margin: 0;
        left: 0;
        padding-top: 5rem;
        padding-left: 1rem;
        transition: 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);

        &::after {
          content: "";
          position: absolute;
          background: url("../images/menuboard_bg.webp") top center no-repeat;
          width: 23.75rem;
          height: 100%;
          left: -3.3rem;
          top: 0;
          z-index: -1;
        }

        a {
          font-size: 2rem;

          @media screen and (max-width: 64rem) {
            font-size: 1.125rem;
          }

          @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
              animation: bounce-in 1s $i * 0.04s ease-out forwards;
            }
          }
        }
      }
    }
  }
}

/* Hambuger menu */
.toggle-menu {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  display: none;
  z-index: 100;

  @media screen and (max-width: 64rem) {
    display: block;
    position: absolute;
    left: 1rem;
    top: 2.75rem;
  }

  .toggle {
    position: absolute;
    background: #ffd649;
    width: 100%;
    height: 4px;
    top: 50%;
    right: 0px;
    margin-top: -5px;
    opacity: 1;
    border-radius: 2px;

    &,
    &::before,
    &::after {
      transition: all 0.3s ease-in-out;
    }

    &::before {
      position: absolute;
      background: #ffd649;
      width: 1.5rem;
      height: 4px;
      top: 8px;
      content: "";
      border-radius: 2px;
      display: block;
    }

    &::after {
      position: absolute;
      background: #ffd649;
      width: 1.5rem;
      height: 4px;
      bottom: 8px;
      content: "";
      border-radius: 2px;
      display: block;
    }
  }

  &.active {
    z-index: 9999;

    .toggle {
      background: rgba(255, 255, 255, 0);

      &::before {
        transform: rotate(45deg);
        top: 0px;
      }

      &::after {
        transform: rotate(-45deg);
        bottom: 0px;
      }
    }
  }
}

.menu_list {
  position: absolute;
  white-space: nowrap;
  height: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  width: 200px;
  margin-top: 0rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: -2;
  transition: 0.3s ease-in-out all;

  @media screen and (max-width: 64rem) {
    position: relative;
    z-index: 1;
    width: auto;
    overflow: inherit;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1rem;
    }

    &::after {
      background: url("../images/menuboard_selection_bot.webp") bottom center no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      bottom: -12px;
      left: 0;
      pointer-events: none;
    }
  }

  &.active {
    height: 150px;
    opacity: 1;
    width: 200px;
    visibility: initial;

    @media screen and (max-width: 64rem) {
      width: 235px;
      height: 100px;
      position: relative;
      left: 6rem;
      background: #4f2122;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    li {
      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          animation: bounce-in 1s $i * 0.04s ease-out forwards;
        }
      }

      @media screen and (max-width: 64rem) {
        &:not(:last-child) {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    background: url("../images/board_header.webp") center center no-repeat;
    background-size: contain;
    z-index: -1;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 64rem) {
      background: url("../images/menuboard_selection_top.webp") top center no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      top: -12px;
      left: 0;
      pointer-events: none;
    }
  }

  li {
    text-align: center;
    padding-top: 1rem;
    position: relative;

    @media screen and (max-width: 64rem) {
      text-align: left;
      padding-top: 0;
    }

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      bottom: -1rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 40%;
      border: 2px solid #c2a178;
      border-radius: 100%;

      @media screen and (max-width: 64rem) {
        display: none;
      }
    }

    a {
      color: #6f4140;
      text-shadow: none;
      font-weight: bold;
      transition: 0.5s ease-in-out color;

      @media screen and (max-width: 64rem) {
        color: #ffffff;
        padding: 0.625rem 2rem;
        display: block;
      }

      &:hover,
      &:active {
        color: #a24443;
      }
    }
  }
}

.sub_menu {
  position: relative;

  &_link {
    position: relative;
    cursor: pointer;

    &.active {
      color: #fdd54a !important;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 0.625rem;
      height: 2.125px;
      top: 1.625rem;
      background: #fdd54a;
      border-radius: 1px;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.43);
      transition: 0.2s ease all;

    }

    &::before {
      transform: rotate(60deg);
      right: -14px;
    }

    &::after {
      transform: rotate(-60deg);
      right: -18px;
    }

    &.active::before,
    &.active::after {
      position: absolute;
      width: 0.625rem;
      height: 2.125px;
      top: 1.625rem;
      background: #fdd54a;
      border-radius: 1px;
      box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.43);
      transition: 0.2s ease all;
    }

    &.active::before {
      transform: rotate(-60deg);
      right: -14px;
    }

    &.active::after {
      transform: rotate(60deg);
      right: -18px;
    }
  }
}

.next-arrow,
.prev-arrow {
  background: url('../images/icon_arrow.webp') center center no-repeat;
  background-size: cover;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: 0.2s ease all;
  cursor: pointer;

  &:active {
    background: url('../images/icon_arrow_tab.webp') center center no-repeat;
    background-size: cover;
  }

  &.slick-disabled {
    display: none;
  }
}

.next-arrow {
  transform: rotate(0deg);
  right: -1.5rem;

  @media screen and (min-width: 92rem) {
    right: 0rem;
  }
}

.prev-arrow {
  transform: rotate(-180deg);
  left: -1.5rem;

  @media screen and (min-width: 92rem) {
    left: 0rem;
  }
}

.banner {
  position: relative;
  width: 100%;
  min-height: 100%;

  .main_title {
    position: absolute;
    bottom: 11rem;
    width: 100%;
    z-index: 10;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::before {
      top: -2rem;
      left: 0;
      transform: scale(1.2);
    }

    a {
      color: #FDD54A;
      font-weight: bold;
      display: block;

      @media screen and (max-width: 36rem) {
        margin-top: 0.5rem;
      }
    }

    h3 {
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 1.5rem;

      @media screen and (max-width: 36rem) {
        font-weight: bold;
        font-size: 1.625rem;

        @media screen and (max-width: 26rem) {
          font-size: 1.125rem;
          line-height: 1rem;
        }
      }
    }

    p {
      @media screen and (max-width: 26rem) {
        font-size: 0.875rem;
      }
    }
  }

  &_bg {
    display: none;
  }

  @media screen and (max-width: 48rem) {
    .main_title {
      width: 100%;
      top: auto;


      &::before {
        background-size: cover;
        display: flex;
        top: 0;
        height: 7.5rem;
      }
    }

    video {
      display: none;
      visibility: hidden;
    }

    &_bg {
      display: block;

      @media screen and (max-width: 48rem) {
        width: 100%;
        height: 100vh;
        object-fit: cover;
      }

      @media screen and (max-width: 36rem) {
        height: auto;
      }
    }
  }
}

.play_buton {
  position: absolute;
  width: 12.5rem;
  height: auto;
  right: 0;
  top: 0rem;
  z-index: 1001;

  @media screen and (max-width: 85rem) {
    z-index: 20;
  }

  .play {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .play_img {
    position: absolute;
    right: 5rem;
    animation: wave1 4s alternate infinite;
  }

  &.anim {
    .wheel {
      animation: waveanim 0.4s linear infinite;
      animation-iteration-count: 1;
      transition: 0.5s ease all;
    }

    .play_game {
      animation: bounce-in 0.5s ease-out forwards;
    }
  }

  ul {
    position: absolute;
    top: 10rem;
    left: -5rem;
    text-transform: uppercase;
    transition: 0.5s ease all;
    width: 200px;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    background: url("../images/dropdown_play.webp") top center no-repeat;
    background-size: contain;
    z-index: -4;
    // pointer-events: none;

    li {
      position: relative;
      text-align: center;
      left: 0;
      top: 6.25rem;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    a {
      font-size: 19px;
      color: #ffffff;
      font-family: "Signika", sans-serif;
      font-weight: bold;
      transition: 0.2s ease all;
      text-shadow: 1px 0 0 #4c0000, -1px 0 0 #4c0000, 0 1px 0 #4c0000,
        0 -1px 0 #4c0000, 1px 1px #4c0000, -1px -1px 0 #4c0000,
        1px -1px 0 #4c0000, -1px 1px 0 #4c0000;

      &:hover {
        color: #fdd54a;
        text-shadow: none;
      }
    }

    &.active {
      height: 200px;
      z-index: -1;
      visibility: visible;
      transition: 0.5s ease all;
      animation: bounce-in 0.5s ease-out forwards;
    }
  }

  .board {
    top: 1rem;
    right: 6.5rem;
    width: 10.5rem;
    height: 8.25rem;
    z-index: -3;
    animation: wave1 2s alternate infinite;
  }

  .sword {
    width: 13.5rem;
    height: 7.5rem;
    top: 4rem;
    z-index: -2;
  }

  .wheel {
    top: 4rem;
    width: 12.75rem;
    height: 12.75rem;
    animation: wave 4s alternate infinite;
    transition: 0.5s ease all;
    z-index: -1;
  }

  .play_game {
    top: 11rem;
    right: 5rem;
    width: 14.75rem;
    height: 4.125rem;
  }

  @media screen and (max-width: 85rem) {
    & {
      top: 6vh;
      width: 9rem;
      left: 50%;
      z-index: 3;
      transform: translateX(-50%);
    }

    ul {
      left: -1.5rem;
      width: 185px;
      top: 9rem;

      li {
        top: 5.75rem;
      }
    }

    .play_game {
      top: 11rem;
      left: 0;
      width: 100%;
      height: auto;
    }

    .wheel {
      left: 0;
      top: 6rem;
      width: 100%;
      height: auto;
    }

    .sword {
      width: 100%;
      height: auto;
      top: 6rem;
      left: 0;
    }

    .board {
      top: 2.5rem;
      left: 0;
      width: 100%;
      height: auto;
    }
  }
}

.slider_head {
  position: relative;
  z-index: 5;
  top: 0rem;
  background: #0d2334;
  box-shadow: 0px -10px 60px 80px rgba(13, 35, 52, 1);

  @media only screen and (max-width: 48rem) {
    // top: -8rem;
    background-color: #0e2335;
  }

  @media only screen and (max-width: 36rem) {
    top: -8rem;
  }

  &--main {
    display: flex;
    justify-content: space-around;
    position: relative;
    top: -10rem;

    @media screen and (max-width: 36rem) {
      flex-direction: column;
    }
  }

  .slider_item {
    position: relative;

    &:not(:last-child) {
      margin-right: 1.5rem;

      @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
        margin-right: 0;
      }
    }
  }
}

.common_title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  background: url("../images/common_title.webp") center center no-repeat;
  background-size: cover;
  width: 333px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  color: #573b33;
  z-index: 2;

  @media screen and (max-width: 80rem) {
    width: 250px;
    height: 80px;
    font-size: 1.75rem;
  }

  @media screen and (max-width: 36rem) {
    width: 200px;
    height: 65px;
    font-size: 1.75rem;
  }

  span {
    margin-top: -1rem;
  }
}

.deco_fore_01,
.deco_fore_02,
.deco_fore_03,
.deco_fore_04 {
  position: absolute;
  max-width: 80px;
  height: auto;
  z-index: 10;

  @media screen and (max-width: 36rem) {
    max-width: 50px;
  }
}

.world {
  background: url("../images/world.webp") no-repeat top center;
  background-size: cover;
  width: 100%;
  height: 190vh;
  position: relative;

  .world_bottom {
    top: -8rem;

    @media screen and (min-width: 92rem) {
      top: -35rem;
    }

    @media screen and (max-width: 36rem) {
      margin-top: 20vh !important;
      top: 13rem;
    }

    @media screen and (max-width: 26rem) {
      top: 7rem;
    }

    @media screen and (max-width: 23.4375rem) {
      top: 13rem;
    }

    @media screen and (max-width: 22.5rem) {
      top: 11rem;
    }

    @media screen and (max-width: 23.4375rem) {
      margin-top: 0vh !important;
    }
  }

  .mounth_bg {
    width: 100vw;
  }

  .tower_bg {
    position: relative;

    @media screen and (max-width: 36rem) {
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 0;
        left: 0;
        box-shadow: 0px -10px 60px 80px #131824;
      }
    }
  }

  .main_title {
    max-width: 80%;
    padding: 0 2rem;
    z-index: 1000;

    &_mobile {
      margin-top: 60vh;

      @media screen and (min-width: 92rem) {
        margin-top: 130vh;
      }

      @media screen and (max-width: 90rem) {
        margin-top: 60vh;
      }
    }

    @media screen and (max-width: 36rem) {
      max-width: 100%;
    }

    h3 {
      font-size: 2rem;
      font-weight: 500;
    }

    &_top {
      z-index: 5;
      top: -5rem;

      h3 {
        font-weight: 400;
      }

      &::before {
        background: none;
      }
    }
  }

  .deco_fore_03 {
    right: 0;
    bottom: 0;
    transform: scaleX(-1);
  }

  @media screen and (max-width: 36rem) {
    margin-top: -10rem;
    max-height: 120vh;

    .main_title {
      &_top {
        top: -6rem;
      }

      h2 {
        font-size: 3rem;
        line-height: 3rem;
      }

      h3 {
        font-size: 1.5rem;
      }
    }
  }

  &_mounth {
    background: url("../images/bg_battle_2.webp") no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: -15rem;

    @media screen and (max-width: 36rem) {
      top: -19rem;
    }
  }

  .crab {
    position: absolute;
    z-index: 1;
  }

  .crab_6,
  .crab_5,
  .crab_3 {
    max-width: 134px;

    @media screen and (max-width: 36rem) {
      max-width: 20%;
    }
  }

  .crab_6 {
    top: 9%;
    left: 28%;

    @media screen and (max-width: 36rem) {
      top: 12%;
      left: 20%;
    }
  }

  .crab_4 {
    top: 3%;
    right: 8%;
    max-width: 250px;

    @media screen and (max-width: 36rem) {
      max-width: 30%;
      top: 6%;
      right: -2rem;
    }
  }

  .crab_5 {
    top: 17%;
    left: 50%;

    @media screen and (min-width: 92rem) {
      top: 17%;
    }

    @media screen and (min-width: 100rem) {
      top: 16%;
    }

    @media screen and (width: 2560px) {
      top: 14%;
    }

    @media screen and (max-width: 36rem) {
      top: 13%;
      left: 48%;
      max-width: 17%;
    }

    @media screen and (max-width: 26rem) {
      top: 15%;
    }

    @media screen and (max-width: 24.375rem) {
      top: 17%;
    }
  }

  .crab_3 {
    top: 30%;
    right: 20%;

    @media screen and (min-width: 92rem) {
      top: 30%;
    }

    @media screen and (min-width: 100rem) {
      top: 28%;
    }

    @media screen and (width: 2560px) {
      top: 26%;
    }

    @media screen and (max-width: 36rem) {
      top: 33%;
    }

    @media screen and (max-width: 26rem) {
      top: 31%;
    }

    @media screen and (max-width: 24.375rem) {
      top: 33%;
    }
  }

  .crab_1 {
    top: 33%;
    left: -40%;
    max-width: 55%;
    height: auto;

    @media screen and (min-width: 100rem) {
      left: -35%;
    }

    @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
      top: 70%;
      left: -110%;
      max-width: 70%;
    }
  }

  .crab_2 {
    top: 38%;
    right: -30%;
    max-width: 400px;

    @media screen and (min-width: 100rem) {
      right: -20%;
    }

    @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
      top: 70%;
      right: -115%;
      max-width: 50%;
    }
  }

  .dragon {
    position: relative;
    top: 0%;
    right: -4rem;

    img {
      @media screen and (min-width: 92rem) {
        transform: scale(1.3);
        transform-origin: right;
      }

      @media screen and (width: 2560px) {
        transform: scale(1.7);
      }
    }

    @media screen and (min-width: 92rem) {
      position: absolute;
      right: 0;
      top: 10%;
    }

    @media screen and (max-width: 36rem) {
      top: 0%;
      transform: scale(1.4) translateX(10%) !important;
      right: -1rem;
    }
  }

  .mount {
    position: absolute;
    top: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 36rem) {
      top: 10%;
    }

    @media screen and (max-width: 23.5rem) {
      top: 8%;
    }
  }

  .seashell {
    position: relative;
    top: 4rem;
    z-index: 100;

    @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
      top: 1rem;
      left: 1rem;
    }

    img {
      position: relative;
    }

    &_down,
    &_up {
      img {
        max-width: 300px;

        @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
          max-width: 115px;
        }
      }
    }

    &_down {
      position: relative;
      top: -6rem;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../images/seashell_fx.webp") center center no-repeat;
        top: 9rem;
        left: -1rem;
        background-size: contain;
        transform: scale(1.8);
        animation: bluranim 1.5s alternate infinite;

        @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
          top: 3rem;
          left: -0.5rem;
        }
      }

      @media screen and (max-width: 36rem) {
        top: -1.5rem;
      }

      img {
        transform-origin: 0 100%;
        transform: translateZ(0);

        animation-name: wobble-top;
        animation-duration: 6s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
      }
    }

    &_coin {
      position: relative;
      z-index: 200;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../images/seashell_fx.webp") center center no-repeat;
        top: -3rem;
        left: -1rem;
        background-size: contain;
        transform: scale(2.5);

        @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
          transform: scale(3.6);
          top: -2rem;
          left: -0.5rem;
        }
      }

      img {
        max-width: 200px;
        top: 5.625rem;
        left: 2.5rem;
        z-index: 1;

        @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
          max-width: 70px;
          top: 2rem;
          left: 1.4rem;
        }
      }
    }

    &_down {
      img {
        top: 13.5rem;
        z-index: -1;

        @media screen and (max-width: 36rem) {
          top: 4.5rem;
        }
      }
    }
  }
}

.battle {
  background: #101d30;
  box-shadow: 0px -10px 60px 80px #111f32;
  margin-top: 2rem;

  @media screen and (min-width: 100rem) {
    margin-top: 6rem;
  }

  .slick-list {
    @media screen and (min-width: 75rem) {
      overflow: visible;
    }
  }

  .main_title {
    max-width: 60% !important;
    padding: 0 2rem;

    @media screen and (max-width: 48rem) {
      padding: 0;
      max-width: 100% !important;
    }

    h3 {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .main_title_bottom {
    max-width: 50%;

    @media screen and (max-width: 48rem) {
      max-width: 100%;
    }

    &::before {
      background: none;
    }

    h3 {
      font-size: 2rem;
      font-weight: 500;

    }
  }

  @media screen and (max-width: 60rem) {
    background: linear-gradient(180deg, #101d30 0%, #285c5e 80%);
  }

  @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
    margin-top: 9rem;
  }

  .story_main {
    position: relative;
    padding: 6rem 2rem 2rem;
    height: 700px;
    transition: 0.3s ease all;

    @media screen and (min-width: 100rem) {
      padding: 6rem 2.5rem 2rem;
    }

    @media screen and (max-width: 80rem) {
      padding: 6rem 1.5rem 2rem;
    }

    @media screen and (max-width: 75rem) {
      padding: 6rem 3rem 2rem;
    }

    @media screen and (max-width: 62rem) {
      padding: 6rem 2rem 2rem;
    }

    @media screen and (max-width: 60rem) {
      padding: 6rem 1.5rem 2rem;
    }

    @media screen and (max-width: 48rem) {
      padding: 6rem 1rem 2rem;
    }

    @media screen and (max-width: 36rem) {
      padding: 10rem 1.5rem 2rem;
      height: 720px;
    }

    @media screen and (max-width: 26rem) {
      height: 700px;
    }

    @media screen and (max-width: 22.5rem) {
      height: 630px;
    }

    &::before {
      content: "";
      position: absolute;
      background: url("../images/board_info.webp") center center no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: scaleX(1) scaleY(1);

      @media screen and (max-width: 36rem) {
        transform: scaleX(1) scaleY(1.2);
      }

      @media screen and (max-width: 26rem) {
        transform: scaleX(1) scaleY(1.1);
      }
    }
  }

  .slider_head--list {
    margin: 5rem 0 3rem 0;

    @media screen and (max-width: 48rem) {
      margin: 0rem 0 -2rem 0;
    }

    @media screen and (max-width: 36rem) {
      margin: -2rem 0 2rem 0;
    }
  }

  .story_contain {
    position: relative;
    top: -4rem;
    z-index: 1;

    @media screen and (max-width: 80rem) {
      top: -5rem;
    }

    @media screen and (max-width: 48rem) {
      top: -6rem;
    }

    @media screen and (max-width: 36rem) {
      top: 0rem;
    }

    &::before {
      content: "";
      position: absolute;
      background: url("../images/board_paper.webp") center center no-repeat;
      background-size: cover;
      filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.75));
      height: 450px;
      width: 100%;
      top: 4.75rem;
      left: 0;
      z-index: -1;

      @media only screen and (max-width: 80rem) {
        top: 6.75rem;
      }

      @media only screen and (max-width: 48rem) {
        background-size: contain;
        top: 5.5rem;
        height: 100%;
        transform: scaleY(1.1);
      }

      @media screen and (max-width: 26rem) {
        top: 0rem;
        transform: scaleY(1.3);
      }
    }

    &_img {
      position: relative;

      @media screen and (max-width: 36rem) {
        top: -2rem;
      }

      @media screen and (max-width: 26rem) {
        top: -2.5rem;
      }

      @media screen and (max-width: 24rem) {
        top: -1rem;
      }

      .gamemode_mineloot_a,
      .gamemode_adventure_a,
      .gamemode_breed_a {
        position: absolute;
      }

      .gamemode_mineloot_a {
        right: 0;
        bottom: 1rem;
        max-width: 230px;

        @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
          max-width: 200px;
        }
      }

      .gamemode_adventure_a {
        left: -1rem;
        bottom: 0;
        max-width: 350px;
      }

      .gamemode_breed_a {
        left: -1rem;
        bottom: 0;
        max-width: 390px;

        @media screen and (max-width: 48rem) {
          left: 0;
          max-width: 330px;
        }
      }

      img {
        padding-top: 7rem;

        @media screen and (min-width: 90rem) {
          padding-top: 5rem;
        }

        @media screen and (max-width: 48rem) {
          padding-top: 9rem;
        }

        @media screen and (max-width: 36rem) {
          width: 100%;
          margin: 0 auto;
          padding-top: 0.5rem;
        }

        @media screen and (max-width: 26rem) {
          padding-top: 1.5rem;
        }

        @media screen and (max-width: 23.4375rem) {
          padding-top: 0rem;
        }
      }
    }
  }

  .story_info {
    text-align: center;
    color: #573b33;
    padding: 0.625rem;

    @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
      padding: 1rem;
      position: relative;
      top: -1.5rem;
    }

    h3 {
      font-size: 2rem;
      line-height: 2.5rem;
      max-width: 80%;
      margin: 0 auto;
      margin-bottom: 1rem;

      @media screen and (max-width: 48rem) {
        max-width: 100%;
        margin-bottom: 0.5rem;
      }

      @media screen and (max-width: 36rem) {
        font-size: 1.75rem;
        line-height: 1.75rem;
      }

    }
  }
}

.parts {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    top: 4rem;
    box-shadow: 0px -10px 60px 80px #131824;

    @media screen and (max-width: 80rem) {
      top: 0;
    }

    @media screen and (max-width: 36rem) {
      top: auto;
    }
  }

  @media screen and (max-width: 80rem) {
    background-color: #131824;
  }

  .main_title {
    margin-top: 5rem;
    padding: 0 2rem;
    max-width: 50%;

    &::before {
      background: none;
    }

    @media screen and (max-width: 48rem) {
      margin-top: -3rem;
      max-width: 100%;
    }

    h3 {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .deco_fore_03 {
    top: -8rem;
    left: 0;
  }

  .deco_fore_04 {
    top: 0;
    right: 0;
    transform: scaleX(-1);
  }

  .deco_fore_01 {
    bottom: 0;
    right: 0;
    transform: scaleX(-1);
  }

  @media screen and (max-width: 36rem) {
    margin-top: -5rem;
    background: #285c5e;
    padding-top: 5rem;
  }

  .parts_contain {
    position: relative;

    @media screen and (max-width: 48rem) {
      top: -5rem;
    }

    @media screen and (max-width: 36rem) {
      top: 0rem;
    }

    &::before {
      content: "";
      position: absolute;
      top: 38rem;
      background: url("../images/book_base.webp") top center no-repeat;
      background-size: contain;
      width: 100%;
      height: 3129px;

      @media screen and (min-width: 92rem) {
        top: 50rem;
      }

      @media screen and (max-width: 48rem) {
        top: 21rem;
        background: url("../images/book_base_mobile.webp") top center no-repeat;
        background-size: contain !important;
      }
    }

    &::after {
      content: "";
      position: absolute;
      background: url("../images/bg_book.webp") bottom center no-repeat;
      background-size: cover;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      padding-top: 30rem;

      @media screen and (max-width: 36rem) {
        top: -6rem;
      }
    }
  }

  &_book {
    position: relative;
    // max-width: 60%;
    margin: 0 auto;
    padding-top: 15rem;

    @media screen and (min-width: 92rem) {
      padding-top: 25rem;
    }

    @media screen and (max-width: 80rem) {
      max-width: 100%;
    }

    img {
      position: relative;
      z-index: 1;
      display: flex;
      margin: 0 auto;
      width: 70%;
      top: -8rem;

      @media screen and (min-width: 92rem) {
        top: -18rem;
      }

      @media screen and (max-width: 80rem) {
        transform: scale(1);
        width: 100%;
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &::after {
      background: url("../images/particle_back.webp") center center no-repeat;
      background-size: contain;
      transform: scale(1.1);
      animation: bluranim 2s alternate infinite;
      top: 0;
    }

    &::before {
      background: url("../images/fx_back.webp") center center no-repeat;
      background-size: contain;
      transform: scale(1.9);
      bottom: 0rem;
      pointer-events: none;

      @media screen and (max-width: 36rem) {
        transform: scale(1.5);
        bottom: -1rem;
      }
    }
  }
}

.download {
  position: relative;
  z-index: 1;
  margin-top: 16rem;

  @media screen and (min-width: 92rem) {
    margin-top: 12rem;
  }

  @media screen and (max-width: 48rem) {
    margin-top: 4rem;
  }

  @media screen and (max-width: 36rem) {
    margin-top: 4rem;
  }

  .main_title {
    max-width: 50%;
    padding: 0;

    @media screen and (max-width: 48rem) {
      max-width: 100%;
    }

    &::before {
      background: none;
    }

    h3 {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .deco_fore_02 {
    bottom: 0;
    right: 0;
    transform: scaleX(-1);
  }

  .deco_fore_04 {
    top: -8rem;
    left: 0;
  }

  @media screen and (max-width: 36rem) {
    margin-top: 8rem;
  }

  &_title {
    text-align: center;
    font-size: 2.5rem;
    position: relative;
    top: -4rem;
    color: #faddb5;

    @media screen and (max-width: 48rem) {
      top: 1rem;
      margin: 3rem 0;
    }
  }

  &_brand {
    position: relative;
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 0.5rem;

    @media screen and (max-width: 80rem) {
      width: 90px;
      height: 90px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: url("../images/logo_bubble.webp") center center no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
    }

    img {
      width: 60px;

      @media screen and (max-width: 80rem) {
        width: 45px;
      }
    }
  }

  &_list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin: 1rem auto 0 auto;

    @media screen and (max-width: 62rem) {
      padding: 0 4rem;
    }

    @media screen and (max-width: 48rem) {
      flex-wrap: wrap;
      grid-template-columns: repeat(2, 1fr);
      padding: 0;
      justify-content: center;
    }
  }

  &_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 2rem;

    &:hover,
    &:active {
      .download_brand {
        &::before {
          animation-name: wobble;
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: 1;
        }
      }
    }

    @media screen and (max-width: 48rem) {
      margin: 0 1rem;
      margin-bottom: 1.5rem;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../images/download_button.webp") center center no-repeat;
      background-size: contain;
      width: 125px;
      height: 50px;
      font-weight: bold;
      color: #ffffff;
    }
  }

  &_bg {
    position: relative;
    width: 100%;
    height: 500px;
    margin: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: url("../images/download_bg.webp") center center no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      z-index: -1;

      @media screen and (max-width: 48rem) {
        display: none;
      }
    }
  }
}

.slick-dots {
  display: none !important;
}

.roadmap {
  position: relative;
  z-index: 2;
  margin-top: -110rem;

  @media screen and (width: 2560px) {
    margin-top: -100rem !important;
  }

  @media screen and (min-width: 92rem) {
    margin-top: -105rem;
  }

  .deco_fore_04 {
    top: 30%;
    max-width: 90px;
    right: 0;
    transform: scaleX(-1);

    @media screen and (max-width: 36rem) {
      max-width: 50px;
    }
  }

  .deco_fore_02 {
    bottom: 0;
    left: 0;
  }

  @media screen and (max-width: 80rem) {
    margin-top: -120rem;
  }

  @media screen and (max-width: 64rem) {
    margin-top: -108rem;
  }

  @media screen and (max-width: 36rem) {
    margin-top: -90rem;
  }

  .common_title {
    top: 5rem;

    @media screen and (max-width: 80rem) {
      top: 6rem;
    }

    @media screen and (max-width: 48rem) {
      top: 2rem;
    }

    @media screen and (max-width: 36rem) {
      top: 1rem;
    }
  }

  &_bg {
    position: relative;
    width: 100%;
    height: 60rem;
    padding: 8rem 8rem 8px 8rem;

    &::before {
      content: "";
      position: absolute;
      background: url("../images/roadmap_bg.webp") center center no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      @media screen and (max-width: 60rem) {
        transform: scaleY(1.2);
      }

      @media screen and (max-width: 48rem) {
        transform: none;
        background: url("../images/roadmap_bg_mobile.webp") center center no-repeat;
        background-size: contain;
        height: inherit;
      }
    }

    @media screen and (max-width: 80rem) {
      height: 53rem;
      padding: 3.5rem 5rem 8px 5rem;
    }

    @media screen and (max-width: 48rem) {
      height: 53rem;
      padding: 3.5rem 9rem 8px 9rem;
    }

    @media screen and (max-width: 75rem) {
      height: 50rem;
    }

    @media screen and (max-width: 42.5rem) {
      padding: 4rem 6rem 8px 6rem;
    }

    @media screen and (max-width: 36rem) {
      padding: 4rem 3rem 8px 3rem;
    }

    @media screen and (max-width: 26rem) {
      padding: 4rem 1rem 8px 1rem;
      height: 38rem;
    }
  }

  &_item {
    position: relative;
    padding: 1.5rem;
    color: #7d4d37;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 80%;
    margin: 0 auto;

    @media screen and (max-width: 80rem) {
      align-items: center;
    }

    @media screen and (max-width: 36rem) {
      top: -3rem;
      max-width: 100%;
    }

    h2 {
      text-align: center;
      font-size: 2rem;
      line-height: 3rem;
      margin-bottom: 0.75rem;
    }
  }

  &_imgboat {
    max-width: 11rem;
    margin: 0 auto;

    @media screen and (max-width: 26rem) {
      max-width: 8rem;
    }
  }

  &_img_anim {
    position: absolute;
    top: 9rem;
    right: 9rem;
    max-width: 4rem;
    animation: wave 5s alternate infinite;

    @media screen and (max-width: 48rem) {
      right: 12rem;
    }

    @media screen and (max-width: 36rem) {
      right: 8rem;
    }

    @media screen and (max-width: 26rem) {
      top: 6rem;
      right: 10rem;
    }
  }

  p {
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  ul {
    list-style: disc;
    font-weight: 300;

    li:not(:last-child) {
      padding-bottom: 0.5rem;
    }
  }
}


.backers {
  position: relative;
  z-index: 2;
  margin: 8rem 0;

  @media screen and (max-width: 48rem) {
    margin: 22rem 0 8rem 0;
  }

  @media screen and (max-width: 36rem) {
    margin-top: 10rem;
  }

  .common_title {
    top: -4rem;

    @media screen and (max-width: 48rem) {
      top: -16rem;
    }

    @media screen and (max-width: 36rem) {
      top: -7rem;
    }
  }

  &_bg {
    width: 100%;
    padding: 4rem 15rem;
    height: 25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media screen and (max-width: 80rem) {
      padding: 4rem 8rem;
    }

    @media screen and (max-width: 75rem) {
      justify-content: space-around;
      padding: 4rem 8rem;
    }

    @media screen and (max-width: 64rem) {
      padding: 4rem 4rem;
    }

    @media screen and (max-width: 62rem) {
      padding: 1rem 3rem;
    }

    @media screen and (max-width: 48rem) {
      flex-direction: column;
      flex-wrap: initial;
      padding: 10rem 0rem;
    }

    @media screen and (max-width: 36rem) {
      padding: 5rem 0rem;
    }

    &::before {
      content: "";
      position: absolute;
      background: url("../images/backer_bg.webp") center center no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      transform: scaleY(1.2);

      @media screen and (max-width: 64rem) {
        transform: scaleY(1.3);
      }

      @media screen and (max-width: 62rem) {
        transform: scaleY(1.5);
      }

      @media screen and (max-width: 48rem) {
        background: url("../images/backer_bg_mobile.webp") center center no-repeat;
        height: 100%;
        width: 100%;
        background-size: contain;
        flex-direction: column;
        flex-wrap: inherit;
        z-index: -1;
        transform: scaleX(1.5) scaleY(2.2);
      }

      @media screen and (max-width: 36rem) {
        transform: scaleX(1.2) scaleY(1.5);
      }
    }
  }

  @media screen and (max-width: 36rem) {
    &_brand {
      &:not(:last-child) {
        margin-bottom: 0rem;
      }

      img {
        max-width: auto;
        max-height: 4rem;
      }
    }
  }
}

@keyframes wave {
  0% {
    transform: rotate(-20deg);
  }

  100% {
    transform: rotate(20deg);
  }
}

@keyframes waveanim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.ocean {
  position: relative;
  z-index: -1;
  margin-bottom: -2rem;
  margin-top: -100rem;
  overflow: hidden;

  @media screen and (min-width: 22.5rem) and (max-width: 36rem) {
    margin-top: -36rem;
  }

  &-back {
    background: url("../images/bg_01.webp") center center no-repeat;
    background-size: contain;
    width: 100%;
    height: 2800px;
    position: relative;
    bottom: -105rem;

    @media screen and (min-width: 110rem) {
      bottom: -100rem !important;
    }

    @media screen and (min-width: 92rem) {
      bottom: -114rem;
    }

    @media screen and (max-width: 36rem) {
      height: 1000px;
      bottom: -85rem;
    }

    @media screen and (max-width: 24.375rem) {
      bottom: -87rem;
    }

  }

  .cra_f {
    position: relative;
    z-index: 2;
    bottom: 0;

    img {
      position: absolute;
      height: auto;
    }

    &_1 {
      bottom: -80rem;
      right: 27rem;
      width: 400px;

      @media screen and (min-width: 92rem) {
        bottom: -95rem;
      }

      @media screen and (max-width: 80rem) {
        width: 300px;
        bottom: -84rem;
        right: 20rem;
      }

      @media screen and (max-width: 75rem) {
        width: 250px;
        bottom: -88rem;
      }

      @media screen and (max-width: 62rem) {
        width: 220px;
        right: 16rem;
        bottom: -94rem;
      }

      @media screen and (max-width: 48rem) {
        width: 180px;
        bottom: -95rem;
      }

      @media screen and (max-width: 38rem) {
        right: 9rem;
      }

      @media screen and (max-width: 36rem) {
        right: 8rem;
        bottom: -84rem;
      }

      @media screen and (max-width: 26rem) {
        right: 6rem;
        bottom: -75rem;
        width: 120px;
      }

      @media screen and (max-width: 24.375rem) {
        bottom: -74rem;
      }

      @media screen and (max-width: 23.5rem) {
        bottom: -73rem;
      }

      @media screen and (max-width: 22.5rem) {
        bottom: -71rem;
      }
    }

    &_2 {
      bottom: -136rem;
      right: 0rem;
      width: 500px;
      position: relative;

      @media screen and (min-width: 92rem) {
        bottom: -154rem;
      }

      @media screen and (max-width: 80rem) {
        width: 400px;
        bottom: -139rem;
      }

      @media screen and (max-width: 75rem) {
        width: 350px;
        bottom: -142rem;
      }

      @media screen and (max-width: 62rem) {
        width: 282px;
      }

      @media screen and (max-width: 48rem) {
        width: 252px;
      }

      @media screen and (max-width: 38rem) {
        bottom: 11rem;
      }

      @media screen and (max-width: 36rem) {
        bottom: -134rem;
        right: -2rem;
      }

      @media screen and (max-width: 26rem) {
        width: 180px;
        bottom: -119rem;
      }

      @media screen and (max-width: 24.375rem) {
        bottom: -117rem;
      }

      @media screen and (max-width: 23.5rem) {
        bottom: -116rem;
      }

      @media screen and (max-width: 22.5rem) {
        bottom: -113rem;
      }
    }

    &_3 {
      bottom: 4rem;
      left: 3rem;
      width: 616px;

      display: inline-block;
      transform-origin: 0 100%;
      transform: translateZ(0);

      animation-name: wobble-top;
      animation-duration: 6s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;

      @media screen and (max-width: 80rem) {
        width: 500px;
      }

      @media screen and (max-width: 75rem) {
        width: 450px;
      }

      @media screen and (max-width: 62rem) {
        width: 350px;
      }

      @media screen and (max-width: 48rem) {
        width: 300px;
      }

      @media screen and (max-width: 38rem) {
        width: 280px;
      }

      @media screen and (max-width: 36rem) {
        left: 1rem;
        width: 250px;
      }

      @media screen and (max-width: 26rem) {
        width: 160px;
      }
    }
  }

  &-front {
    background: url("../images/bg_03.webp") bottom center no-repeat;
    background-size: contain;
    width: 100%;
    height: 2666px;
    position: absolute;
    bottom: -212rem;

    @media screen and (width: 2560px) {
      background-size: cover;
      bottom: -267rem !important;
    }

    @media screen and (min-width: 92rem) {
      bottom: -234rem;
    }

    @media screen and (max-width: 75rem) {
      bottom: -201rem;
    }

    @media screen and (max-width: 64rem) {
      bottom: -204rem;
    }

    @media screen and (max-width: 62rem) {
      bottom: -200rem;
    }

    @media screen and (max-width: 48rem) {
      bottom: -206rem;
    }

    @media screen and (max-width: 36rem) {
      bottom: -191rem;
    }

    @media screen and (max-width: 26rem) {
      bottom: -165rem;
    }

    @media screen and (max-width: 24.375rem) {
      bottom: -162rem;
    }

    @media screen and (max-width: 23.4375rem) {
      bottom: -160rem;
    }

    @media screen and (max-width: 22.5rem) {
      bottom: -156rem;
    }
  }

  &_footer_right {
    right: 0;
    width: 416px;

    @media screen and (max-width: 75rem) {
      width: 350px;
    }

    @media screen and (max-width: 62rem) {
      width: 250px;
    }

    @media screen and (max-width: 36rem) {
      width: 180px;
    }

    @media screen and (max-width: 26rem) {
      width: 140px;
    }
  }

  &_footer_left {
    left: 0;
    width: 267px;

    @media screen and (max-width: 75rem) {
      width: 230px;
    }

    @media screen and (max-width: 62rem) {
      width: 180px;
    }

    @media screen and (max-width: 36rem) {
      width: 150px;
    }

    @media screen and (max-width: 26rem) {
      width: 100px;
    }
  }

  &_footer_left,
  &_footer_right {
    position: absolute;
    z-index: 1;
    bottom: 0;
    height: auto;

    @media screen and (max-width: 36rem) {
      bottom: 1.5rem;
    }
  }
}

.train {
  position: relative;
  width: 100vw;
  top: 0;
  left: 0;
  height: 2666px;
  z-index: 1;

  .deco_fore_03 {
    left: 0;
    top: 0;

    @media screen and (max-width: 36rem) {
      top: 8rem;
    }
  }

  @media screen and (max-width: 36rem) {
    height: 140rem;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 45vh;
    left: 0;
    background: url("../images/bg_train_mobile.webp") top center no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;

    @media screen and (width: 2560px) {
      top: 33vh;
    }

    @media screen and (min-width: 48rem) {
      background: url("../images/bg_train_pc.webp") top center no-repeat;
      background-size: contain;
    }

    @media screen and (min-width: 120rem) {
      background-size: cover;
      max-height: 200%;
    }

    @media screen and (max-width: 80rem) {
      top: 35vh;
    }

    @media screen and (max-width: 62rem) {
      top: 27vh;
    }

    @media screen and (max-width: 48rem) {
      top: 20vh;
    }

    @media screen and (max-width: 36rem) {
      top: 14vh;
    }
  }

  &_list {
    display: flex;
    margin-top: -3rem;

    @media screen and (width: 2560px) {
      margin-top: -2rem;
    }

    @media screen and (max-width: 62rem) {
      flex-direction: column;
      padding-top: 3.5rem;
    }
  }

  &_img {
    @media screen and (min-width: 120rem) {
      display: flex;
      justify-content: center;

      img {
        margin-top: 10rem;
      }
    }
  }

  &_item {
    text-align: center;

    @media screen and (max-width: 62rem) {
      max-width: 60%;
      margin: 0 auto;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    @media screen and (max-width: 36rem) {
      max-width: 100%;
    }

    h3 {
      font-size: 2rem;
    }

    h4 {
      padding-bottom: 1rem;
    }

    p {
      padding-left: 2rem;
      padding-right: 2rem;

      @media screen and (max-width: 48rem) {
        padding: 0;
      }
    }
  }
}

.lightfish {
  width: 300px;
  height: auto;
  position: absolute;
  left: 0;
  top: 60%;
  z-index: 1;
  object-fit: contain;

  @media screen and (max-width: 36rem) {
    width: 150px;
  }
}

footer {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 1.5rem 0;
  bottom: 0;
  z-index: 200;

  &::before {
    content: "";
    position: absolute;
    background: url("../images/bar_bottom.webp") center center no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    transform: scaleX(2.1);

    @media screen and (max-width: 62rem) {
      background-size: cover;
    }
  }

  @media screen and (max-width: 36rem) {
    padding: 1rem 0;
    height: 70px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_info {
      display: flex;
      align-items: flex-start;

      .copyright {
        @media screen and (max-width: 36rem) {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 10px;
          font-size: 0.75rem;
          text-align: center;
          font-weight: 500;
        }
      }

      img {
        max-width: 14.25rem;
        margin-right: 2rem;

        @media screen and (max-width: 36rem) {
          max-width: 5rem;
        }
      }
    }
  }

  ul {
    display: flex;

    @media screen and (max-width: 48rem) {
      position: absolute;
      bottom: 100%;
      margin-bottom: 0.875rem;
      left: 50%;
      transform: translateX(-50%);
    }

    li {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        position: relative;
        width: 4rem;
        height: 4rem;

        @media screen and (max-width: 80rem) {
          width: 3rem;
          height: 3rem;
        }

        &::before {
          content: "";
          position: absolute;
          background: url("../images/scale_button.webp") center center no-repeat;
          background-size: contain;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        @media screen and (max-width: 36rem) {
          padding: 0.5rem;
        }

        img {
          width: 1.5rem;
          height: auto;
          filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
        }
      }
    }
  }
}

@keyframes wave1 {
  0% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(4px);
  }
}

@keyframes wobble {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }

  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }

  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }

  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }

  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }

  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }

  20% {
    transform: scale(1.1);
  }

  40% {
    transform: scale(0.9);
  }

  60% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(0.96);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes wobble-top {
  16.65% {
    transform: skew(0deg);
  }

  33.3% {
    transform: skew(1deg);
  }

  49.95% {
    transform: skew(-1deg);
  }

  66.6% {
    transform: skew(0deg);
  }

  83.25% {
    transform: skew(-1deg);
  }

  100% {
    transform: skew(0);
  }
}

@keyframes bluranim {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.items-center {
  a {
    font-size: 0.875rem;
    font-weight: bold;
    white-space: nowrap;

    @media screen and (max-width: 36rem) {
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
}