.scrollbar {
    position: absolute;
    border-radius: 24px;
    width: 8px;
}

.scroll-custom {
    &::-webkit-scrollbar-track {
        @extend .scrollbar;
    }

    &::-webkit-scrollbar {
        @extend .scrollbar;
        background: #00000021;
        border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
        @extend .scrollbar;
        background: #8D4247;
    }
}