@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;600;700&display=swap');

$font-family-signika: 'Signika',
  sans-serif;

@mixin fontSignika($fontSize, $fontWeight) {
  font-family: $font-family-signika;
  font-size: $fontSize;
  font-weight: $fontWeight;
}

* {
  transition: width height .8s ease;
}

.bounty {
  font-family: $font-family-signika;
  background: #5B3B39;
  overflow: hidden;

  &+footer {
    background: #5B3B39;
  }

  &-banner {
    background: no-repeat center center/cover;
    // background-image: url('/assets/images/art_header.png');
    // min-height: 662px;

    &::after {
      content: none;
      position: absolute;
      top: calc(662px - 10px - 20px);
      bottom: -16px;
      left: -20px;
      right: -20px;
      background: linear-gradient(180deg, rgba(91, 59, 57, 1), rgba(91, 59, 57, 1));
      filter: blur(10px);
    }

    &::before {
      content: none;
      position: absolute;
      top: 0;
      bottom: 25%;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .content {
      max-width: 800px;
      bottom: 5vw;
      padding: 20px 0;

      .title {
        @include fontSignika(1.75rem, 500);
        padding-bottom: 0.625rem;
        z-index: 1;
      }

      .description {
        @include fontSignika(16px, 400);
        z-index: 1;
      }

      &::before {
        content: "";
        background: url('../../../assets/images/bounty/ui_textbox.svg') no-repeat center center/100% 100%;
        position: absolute;
        max-width: calc(100vw - 20px);
        width: 120%;
        min-height: 100%;
        height: auto;
      }
    }

    @media screen and (max-width: 640px) {
      padding-top: 50px;

      .banner-img {
        width: 100%;
        height: 100vw;
        object-fit: cover;
      }

      .content {
        padding: 20px 20px;

        &::before {
          background-size: cover;
          max-width: unset;
          width: 100%;
        }
      }
    }
  }

  &-list {
    position: relative;

    &>.bounty-content {
      position: relative;
      z-index: 3;

      &>.post-list {
        max-width: 1224px;
        margin: 0 auto;
      }
    }

    &::before {
      content: '';
      position: absolute;
      background: url('../../../assets/images/bounty/paper_01.webp') no-repeat top center/100% auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &::after {
      content: '';
      position: absolute;
      background: url('../../../assets/images/bounty/paper_03.webp') no-repeat bottom center/100% auto;
      top: 0;
      bottom: -10px;
      left: 0;
      right: 0;
    }

    &>.bounty-content {
      min-height: 360px;

      &::before {
        content: '';
        position: absolute;
        background: url('../../../assets/images/bounty/paper_02.webp') repeat-y top center/100% auto;
        top: 32vw;
        bottom: calc(32vw - 10px);
        left: 0;
        right: 0;
      }
    }


    @media screen and (max-width: 1024px) {
      &::before {}

      &::after {}

      &>.bounty-content {

        &::before {}
      }
    }

    @media screen and (max-width: 767px) {

      &::before {}

      &::after {
        bottom: -10px;
      }

      &>.bounty-content {
        &::before {
          bottom: calc(32vw - 44px);
        }
      }
    }

    @media screen and (max-width: 640px) {
      margin-top: -3vw;

      &::before {}

      &::after {
        bottom: -44px;
      }

      &>.bounty-content {
        &::before {}
      }
    }
  }

  &-rope {
    position: absolute;
    background: url('../../../assets/images/bounty/rupe_02.webp') repeat-x left center/auto 100%;
    height: 36px;
    left: 73px;
    right: 74px;
    margin-top: -14px;

    &::before {
      content: '';
      position: absolute;
      height: 55px;
      width: 74px;
      background: url('../../../assets/images/bounty/rupe_01.webp') no-repeat left center/auto 100%;
      top: -10px;
      left: -73px;
    }

    &::after {
      content: '';
      position: absolute;
      height: 55px;
      width: 74px;
      background: url('../../../assets/images/bounty/rupe_03.webp') no-repeat left center/auto 100%;
      top: -10px;
      right: -74px;
    }
  }

  &-loadmore {
    border-radius: 10px;
    font-weight: 700;
    font-size: 20px;
    color: rgba(136, 63, 66, 1);
    border: 2px solid rgba(136, 63, 66, 1);
    box-shadow: 0px 3px 0px rgb(75 28 40);
    transition: all .4s ease;

    &:hover {
      color: #FFD16D;
      background-color: rgba(136, 63, 66, 1);
    }
  }
}

.card {
  &.bounty {
    background: url('../../../assets/images/bounty/bounty_base.webp') no-repeat top center/100% 100%;
    position: relative;
    z-index: 2;
    width: 376px;
    transition: all .2s linear !important;

    .card {
      &__title {
        @include fontSignika(20px, 500);
        margin-top: 56px;
        // padding-bottom: 16px;
        padding-left: 24px;
        padding-right: 24px;
        min-height: 64px;
        color: var(--color-white);
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.75);
        transition: all .4s ease;

        &.cursor-pointer {
          &:hover {
            transform: scale(1.15);
          }
        }
      }

      &-container {
        padding-bottom: 48px;
        height: 100%;
      }

      &-wanted {
        background: url('../../../assets/images/bounty/bounty_paper.webp') no-repeat top center/100% 100%;
        margin: 0 auto;
        max-width: calc(100% - 24px);
        // padding-bottom: 54px;
        min-height: 420px;


        &__image {
          .img {
            position: relative;
            margin-top: 24px;
            // max-width: 100%;
            width: 320px;
            max-width: 85%;
            height: 240px;
            background: center center/cover;

            img {
              display: block;
              object-fit: cover;
            }

            &.box-shadow {
              &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 75%);
                transform: rotate(-180deg);
              }
            }

            &:not(.box-shadow) {
              &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background: linear-gradient(180deg, rgba(220, 186, 139, 1) 0%, rgba(237, 215, 168, 1) 100%);
                box-shadow: inset 0px 0px 20px 20px rgb(220 186 139);
                border-radius: 12px;
                transform: unset;
              }
            }
          }

          &--timer {
            @include fontSignika(25px, 500);
            line-height: 25px;
            text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.75);
            color: #FFD554;
            padding-bottom: 16px;
          }
        }

        &__description {
          @include fontSignika(16px, 300);
          color: #101624;
          margin: 16px 0 0;
          min-height: 48px;
        }
      }

      &-reward {
        // padding-bottom: 48px;

        &-container {}

        &__title {
          margin-bottom: 8px;
          @include fontSignika(24px, 500);
          color: #FFD16D;
          text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
        }

        &__item {
          position: relative;
          width: 64px;
          height: 64px;
          transition: all .4s ease;
          @include fontSignika(16px, 700);
          background: url('../../../assets/images/bounty/item_slot.webp') no-repeat center center/cover;

          * {
            transition: all .4s ease;
          }

          &:hover {
            &>* {
              // transform: scale(1.2);
            }
          }
        }

        &__image {
          width: 100%;
          height: 100%;

          img {
            position: absolute;
            width: 65%;
            height: 65%;
            top: 10px;
            object-fit: contain;

            &[alt*="Box"] {
              width: 88%;
              height: 88%;
              border-radius: 16px;
              top: 3px;
            }
          }
        }

        &--quantity {
          line-height: 16px;
          position: absolute;
          bottom: 10px;
        }
      }
    }

    @media screen and (max-width: 425px) {
      width: 100%;
      max-width: 320px;
    }

    &:hover {
      // @extend .wobble-hor-top;
      transform: scale(1.025) !important;
    }
  }
}

.loadesh {
  &>span {
    margin-top: 100px;
    color: #fff;
    @include fontSignika(20px, 700);
    animation: bounceText .5s linear infinite;
  }

  @keyframes bounceText {
    0% {
      transform: translateY(10%);
    }

    50% {
      transform: translateY(0%);
    }

    100% {
      transform: translateY(10%);
    }
  }

  &-circle {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    background: #ccc;
    border: 4px solid #4973ff;
    // box-shadow: 0 0 0 4px #4973ff;
    border-radius: 50%;
    overflow: hidden;
  }

  &-waves {
    position: relative;
    width: 100%;
    height: 100%;
    background: #4973ff;
    border-radius: 50%;
    box-shadow: inset 0 0 0 75px rgba(0, 0, 0, .5);

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 200%;
      height: 200%;
      top: 0;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -75%);
      background: #000;
    }

    &::before {
      border-radius: 45%;
      background: rgba(73, 115, 255, 1);
      animation: anim 5s linear infinite;
    }

    &::after {
      border-radius: 40%;
      background: rgba(73, 115, 255, .5);
      animation: anim 10s linear infinite;
    }

    @keyframes anim {
      0% {
        transform: translate(-50%, -75%) rotate(0deg);
      }

      100% {
        transform: translate(-50%, -75%) rotate(360deg);
      }
    }
  }
}

.wobble-hor-top {
  animation: wobble-hor-top 0.8s both;

  @keyframes wobble-hor-top {

    0%,
    100% {
      transform: translateX(0%);
      transform-origin: 50% 50%;
    }

    15% {
      transform: translateX(-20px) rotate(3deg);
    }

    30% {
      transform: translateX(10px) rotate(-3deg);
    }

    45% {
      transform: translateX(-5px) rotate(1.8deg);
    }

    60% {
      transform: translateX(4px) rotate(-1.2deg);
    }

    75% {
      transform: translateX(-3px) rotate(0.6deg);
    }
  }

}


.slide-to-top {
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  @keyframes slide-top {
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0);
    }
  }
}

.slide-in-top {
  animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  @keyframes slide-in-top {
    0% {
      transform: translateY(-1000px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

}