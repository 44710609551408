:root {
  --color-text: #000000;
  --color-link: #8D4247;
}

body {
  background: linear-gradient(180deg, #F3D7B1 24.63%, #ECC795 100%);
}

.msg {
  // Background
  background: url("../../../assets/images/bg__msg.png") no-repeat center center/100% 100%;
  width: clamp(320px, 100%, 1069px);

  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, .3);
  // Font Size
  font-size: 20px;
  line-height: 24px;

  &__content {
    // Font for content
    font-weight: 400;
    color: var(--color-text);
  }

  &__links {
    // Font for links
    font-weight: 700;
    color: var(--color-link);

    &>ul {
      &>li {
        +li {
          position: relative;
          margin-left: 12px;
          padding-left: 12px;

          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 50%;
            background: var(--color-link);
            top: 25%;
            left: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    background: none;
    box-shadow: none;
  }
}

.film {
  // Background
  position: relative;
  width: clamp(320px, 100%, 1069px);

  &.have-bg {
    background: url("../../../assets/images/bg-msg-film.png") no-repeat center center/100% 100%;

    &::after {
      // content: "";
      position: absolute;
      background: url("../../../assets/images/bg-msg-film.png") no-repeat center center/100% 100%;
      inset: 0;
    }
  }

  &>ul {
    &>li {
      margin: 0.33vw;
      padding: 0.33vw;
    }
  }
}