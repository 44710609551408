@import '../bounty.scss';

$rootWidth: 700px;
$rootHeight: 800px;

$imgWidth: 320px;
$imgHeight: 240px;

.modal-main {
  width: calc($rootWidth/3*2);
  height: calc($rootHeight/3*2);
  background: url('../../../../assets/images/bounty/bounty_paper.webp') no-repeat top center/100% 100%;

  &__img {
    position: relative;

    img {
      display: flex;
      width: calc($imgWidth/2*3);
      height: calc($imgHeight/2*3);
      object-fit: cover;
    }

    &--timer {
      @include fontSignika(25px, 500);
      line-height: 25px;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.75);
      color: #FFD554;
      padding-bottom: 16px;
    }
  }

  &__description {
    @include fontSignika(16px, 300);
    color: #101624;
    margin: 0 16px 0;
    min-height: 48px;
  }
}