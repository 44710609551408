@import url("https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;700&display=swap");
* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
  width: 100%;
  min-height: 100vh;
}

body {
  font-family: "Signika", sans-serif;
  font-size: 1rem;
  background-color: #0d2334;
  color: #ffffff;
  background: url("../images/bg_color.webp") center center no-repeat;
  overflow-x: hidden;
  background-size: cover;
}

input:focus,
input[type="text"],
input[type="number"],
textarea {
  font-size: 16px !important;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  font-family: "Candara", Arial, sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  padding: 0 5rem;
  margin: 0 auto;
  max-width: 1600px;
}

@media only screen and (max-width: 90rem) {
  .container {
    padding: 0 2rem;
  }
}

p {
  font-weight: 300;
}

.sub_menu_body {
  position: fixed;
  right: -100%;
  z-index: 10000;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  padding: 1.5rem;
}

.sub_menu_body::before {
  content: "";
  position: absolute;
  background: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  height: 100%;
  right: -100%;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

@media screen and (max-width: 48rem) {
  .sub_menu_body {
    top: -100%;
    left: 0;
    right: 0;
    height: 86px;
  }
  .sub_menu_body::before {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(45%, black), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(180deg, black 45%, rgba(0, 0, 0, 0) 100%);
    right: 0%;
    left: 0;
    top: 0;
    height: 100%;
  }
  .sub_menu_body ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.sub_menu_body:hover {
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  z-index: 100;
}

.sub_menu_body:hover::before {
  right: 0;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.sub_menu_body:hover span {
  opacity: 1;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.sub_menu_body:hover span,
.sub_menu_body:hover img {
  -webkit-animation: bounce-in 1s ease-out forwards;
          animation: bounce-in 1s ease-out forwards;
}

.sub_menu_body.show {
  right: 0;
  opacity: 1;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

@media screen and (max-width: 48rem) {
  .sub_menu_body.show {
    padding: 1rem;
    top: 0;
    left: 0;
  }
}

.sub_menu_body.show li:nth-child(1) {
  -webkit-animation: bounce-in 1s 0.1s ease-out forwards;
          animation: bounce-in 1s 0.1s ease-out forwards;
}

.sub_menu_body.show li:nth-child(2) {
  -webkit-animation: bounce-in 1s 0.2s ease-out forwards;
          animation: bounce-in 1s 0.2s ease-out forwards;
}

.sub_menu_body.show li:nth-child(3) {
  -webkit-animation: bounce-in 1s 0.3s ease-out forwards;
          animation: bounce-in 1s 0.3s ease-out forwards;
}

.sub_menu_body.show li:nth-child(4) {
  -webkit-animation: bounce-in 1s 0.4s ease-out forwards;
          animation: bounce-in 1s 0.4s ease-out forwards;
}

.sub_menu_body li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.sub_menu_body li.active a {
  color: red;
}

.sub_menu_body li.active img {
  -webkit-filter: brightness(1.25) drop-shadow(0px 0px 10px #fbb867);
          filter: brightness(1.25) drop-shadow(0px 0px 10px #fbb867);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  max-width: 2.5rem;
}

.sub_menu_body li:not(:last-child) {
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 48rem) {
  .sub_menu_body li:not(:last-child) {
    margin-bottom: 0;
  }
}

.sub_menu_body span {
  opacity: 0;
}

@media screen and (max-width: 36rem) {
  .sub_menu_body span {
    display: none;
  }
}

.sub_menu_body a {
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fee283;
  font-weight: 700;
}

.sub_menu_body a img {
  max-width: 3rem;
  margin-left: 0.625rem;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

@media screen and (max-width: 36rem) {
  .sub_menu_body a img {
    max-width: 2.25rem;
  }
}

.sub_menu_body a:hover img {
  -webkit-filter: brightness(1.25) drop-shadow(0px 0px 10px #fbb867);
          filter: brightness(1.25) drop-shadow(0px 0px 10px #fbb867);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  max-width: 2.5rem;
}

.main_title {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
  padding: 0 2rem;
}

@media screen and (max-width: 36rem) {
  .main_title {
    max-width: 100%;
  }
  .main_title_mobile {
    position: relative;
    top: 10%;
  }
  .main_title_mobile::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url("../images/main_title_bg.webp") center center no-repeat;
    background-size: contain;
    opacity: 0.7;
    width: 100%;
    -webkit-transform: scale(2.8);
            transform: scale(2.8);
    height: 100%;
    z-index: -1;
  }
}

@media screen and (max-width: 36rem) and (max-width: 22.5rem) {
  .main_title_mobile::after {
    -webkit-transform: scale(2.3);
            transform: scale(2.3);
  }
}

.main_title h2 {
  font-size: 4rem;
}

.main_title h3 {
  font-size: 2rem;
  font-weight: 300;
  padding-bottom: 0.625rem;
}

@media screen and (max-width: 36rem) {
  .main_title h3 {
    font-weight: 500;
  }
}

.main_title::before {
  content: "";
  position: absolute;
  background: url("../images/bg_title.webp") center center no-repeat;
  background-size: contain;
  width: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%) scaleY(1.3);
          transform: translateX(-50%) scaleY(1.3);
  height: 157px;
  z-index: -1;
  top: -1.5rem;
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .main_title::before {
    display: none;
  }
}

header {
  position: absolute;
  width: 100%;
  z-index: 1000;
}

@media screen and (max-width: 64rem) {
  header {
    top: -1.5rem;
  }
  header .container {
    padding: 0;
  }
}

header nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-self: start;
}

@media screen and (max-width: 64rem) {
  header nav {
    position: absolute;
    width: 100%;
    height: 7rem;
    z-index: 100;
    overflow: hidden;
  }
  header nav.navbar li:not(:last-child) {
    -webkit-transition: 0.5s ease all;
    transition: 0.5s ease all;
    margin-bottom: 0.5rem !important;
  }
  header nav::before {
    content: "";
    position: absolute;
    background: url("../images/menubar.webp") no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: -0.625rem;
  }
}

@media screen and (max-width: 64rem) and (max-width: 64rem) {
  header nav::before {
    background: url("../images/menubar_mobile.webp") center center no-repeat;
    background-size: cover;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    top: -0.5rem;
    height: 6.5rem;
  }
}

header #logo {
  max-width: 12.5rem;
  height: auto;
}

@media screen and (min-width: 48rem) and (max-width: 86rem) {
  header #logo {
    max-width: 10rem;
  }
}

@media screen and (max-width: 64rem) {
  header #logo .logo-pc {
    display: none;
  }
}

header #logo .logo-mb {
  display: none;
}

@media screen and (max-width: 64rem) {
  header #logo .logo-mb {
    display: block;
    max-width: 8rem;
    position: absolute;
    z-index: 999;
    left: 50%;
    top: 2.25rem;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

header .menu_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  width: 100%;
  height: 7rem;
  left: 50%;
  margin-left: -0.5rem;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media screen and (min-width: 48rem) and (max-width: 86rem) {
  header .menu_nav {
    position: relative;
    left: 0;
    -webkit-transform: none;
            transform: none;
  }
}

header .menu_nav::before {
  content: "";
  position: absolute;
  background: url("../images/menubar.webp") no-repeat center center;
  background-size: contain;
  width: 100%;
  height: 100%;
  max-height: 7rem;
  max-width: 52.5rem;
  z-index: -1;
  margin-left: 1.5rem;
}

@media screen and (max-width: 64rem) {
  header .menu_nav::before {
    background-size: cover;
    display: none;
  }
}

header .menu_nav > li:not(:last-child) {
  margin-right: 2rem;
}

@media screen and (max-width: 64rem) {
  header .menu_nav > li:not(:last-child) {
    -webkit-transition: 0.5s ease all;
    transition: 0.5s ease all;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}

header .menu_nav .sub_menu_link,
header .menu_nav a {
  font-family: "Candara", Arial, sans-serif;
  display: inline-block;
  padding-top: 0.875rem;
  font-size: 1rem;
  left: -10px;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 1px 1px 3px #000000;
  -webkit-transition: 0.5s ease color;
  transition: 0.5s ease color;
}

@media screen and (max-width: 64rem) {
  header .menu_nav .sub_menu_link,
  header .menu_nav a {
    left: 0;
  }
}

header .menu_nav .sub_menu_link:hover, header .menu_nav .sub_menu_link:active,
header .menu_nav a:hover,
header .menu_nav a:active {
  color: #fdd54a;
}

@media screen and (max-width: 64rem) {
  header .menu_nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: -100%;
    opacity: 0;
    margin: 0;
    -webkit-transition: 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition: 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  header .menu_nav.active {
    position: fixed;
    height: 100vh;
    opacity: 1;
    overflow: hidden;
    margin: 0;
    left: 0;
    padding-top: 5rem;
    padding-left: 1rem;
    -webkit-transition: 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition: 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  header .menu_nav.active::after {
    content: "";
    position: absolute;
    background: url("../images/menuboard_bg.webp") top center no-repeat;
    width: 23.75rem;
    height: 100%;
    left: -3.3rem;
    top: 0;
    z-index: -1;
  }
  header .menu_nav.active a {
    font-size: 2rem;
  }
}

@media screen and (max-width: 64rem) and (max-width: 64rem) {
  header .menu_nav.active a {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 64rem) {
  header .menu_nav.active a:nth-child(1) {
    -webkit-animation: bounce-in 1s 0.04s ease-out forwards;
            animation: bounce-in 1s 0.04s ease-out forwards;
  }
  header .menu_nav.active a:nth-child(2) {
    -webkit-animation: bounce-in 1s 0.08s ease-out forwards;
            animation: bounce-in 1s 0.08s ease-out forwards;
  }
  header .menu_nav.active a:nth-child(3) {
    -webkit-animation: bounce-in 1s 0.12s ease-out forwards;
            animation: bounce-in 1s 0.12s ease-out forwards;
  }
  header .menu_nav.active a:nth-child(4) {
    -webkit-animation: bounce-in 1s 0.16s ease-out forwards;
            animation: bounce-in 1s 0.16s ease-out forwards;
  }
  header .menu_nav.active a:nth-child(5) {
    -webkit-animation: bounce-in 1s 0.2s ease-out forwards;
            animation: bounce-in 1s 0.2s ease-out forwards;
  }
}

/* Hambuger menu */
.toggle-menu {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  display: none;
  z-index: 100;
}

@media screen and (max-width: 64rem) {
  .toggle-menu {
    display: block;
    position: absolute;
    left: 1rem;
    top: 2.75rem;
  }
}

.toggle-menu .toggle {
  position: absolute;
  background: #ffd649;
  width: 100%;
  height: 4px;
  top: 50%;
  right: 0px;
  margin-top: -5px;
  opacity: 1;
  border-radius: 2px;
}

.toggle-menu .toggle, .toggle-menu .toggle::before, .toggle-menu .toggle::after {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.toggle-menu .toggle::before {
  position: absolute;
  background: #ffd649;
  width: 1.5rem;
  height: 4px;
  top: 8px;
  content: "";
  border-radius: 2px;
  display: block;
}

.toggle-menu .toggle::after {
  position: absolute;
  background: #ffd649;
  width: 1.5rem;
  height: 4px;
  bottom: 8px;
  content: "";
  border-radius: 2px;
  display: block;
}

.toggle-menu.active {
  z-index: 9999;
}

.toggle-menu.active .toggle {
  background: rgba(255, 255, 255, 0);
}

.toggle-menu.active .toggle::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 0px;
}

.toggle-menu.active .toggle::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  bottom: 0px;
}

.menu_list {
  position: absolute;
  white-space: nowrap;
  height: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  width: 200px;
  margin-top: 0rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -2;
  -webkit-transition: 0.3s ease-in-out all;
  transition: 0.3s ease-in-out all;
}

@media screen and (max-width: 64rem) {
  .menu_list {
    position: relative;
    z-index: 1;
    width: auto;
    overflow: inherit;
  }
  .menu_list::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1rem;
  }
  .menu_list::after {
    background: url("../images/menuboard_selection_bot.webp") bottom center no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    bottom: -12px;
    left: 0;
    pointer-events: none;
  }
}

.menu_list.active {
  height: 150px;
  opacity: 1;
  width: 200px;
  visibility: initial;
}

@media screen and (max-width: 64rem) {
  .menu_list.active {
    width: 235px;
    height: 100px;
    position: relative;
    left: 6rem;
    background: #4f2122;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.menu_list.active li:nth-child(1) {
  -webkit-animation: bounce-in 1s 0.04s ease-out forwards;
          animation: bounce-in 1s 0.04s ease-out forwards;
}

.menu_list.active li:nth-child(2) {
  -webkit-animation: bounce-in 1s 0.08s ease-out forwards;
          animation: bounce-in 1s 0.08s ease-out forwards;
}

.menu_list.active li:nth-child(3) {
  -webkit-animation: bounce-in 1s 0.12s ease-out forwards;
          animation: bounce-in 1s 0.12s ease-out forwards;
}

.menu_list.active li:nth-child(4) {
  -webkit-animation: bounce-in 1s 0.16s ease-out forwards;
          animation: bounce-in 1s 0.16s ease-out forwards;
}

.menu_list.active li:nth-child(5) {
  -webkit-animation: bounce-in 1s 0.2s ease-out forwards;
          animation: bounce-in 1s 0.2s ease-out forwards;
}

@media screen and (max-width: 64rem) {
  .menu_list.active li:not(:last-child) {
    margin-bottom: 0 !important;
  }
}

.menu_list::before {
  content: "";
  position: absolute;
  background: url("../images/board_header.webp") center center no-repeat;
  background-size: contain;
  z-index: -1;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 64rem) {
  .menu_list::before {
    background: url("../images/menuboard_selection_top.webp") top center no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    top: -12px;
    left: 0;
    pointer-events: none;
  }
}

.menu_list li {
  text-align: center;
  padding-top: 1rem;
  position: relative;
}

@media screen and (max-width: 64rem) {
  .menu_list li {
    text-align: left;
    padding-top: 0;
  }
}

.menu_list li:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 40%;
  border: 2px solid #c2a178;
  border-radius: 100%;
}

@media screen and (max-width: 64rem) {
  .menu_list li:not(:last-child)::after {
    display: none;
  }
}

.menu_list li a {
  color: #6f4140;
  text-shadow: none;
  font-weight: bold;
  -webkit-transition: 0.5s ease-in-out color;
  transition: 0.5s ease-in-out color;
}

@media screen and (max-width: 64rem) {
  .menu_list li a {
    color: #ffffff;
    padding: 0.625rem 2rem;
    display: block;
  }
}

.menu_list li a:hover, .menu_list li a:active {
  color: #a24443;
}

.sub_menu {
  position: relative;
}

.sub_menu_link {
  position: relative;
  cursor: pointer;
}

.sub_menu_link.active {
  color: #fdd54a !important;
}

.sub_menu_link::before, .sub_menu_link::after {
  content: "";
  position: absolute;
  width: 0.625rem;
  height: 2.125px;
  top: 1.625rem;
  background: #fdd54a;
  border-radius: 1px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.43);
          box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.43);
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
}

.sub_menu_link::before {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  right: -14px;
}

.sub_menu_link::after {
  -webkit-transform: rotate(-60deg);
          transform: rotate(-60deg);
  right: -18px;
}

.sub_menu_link.active::before, .sub_menu_link.active::after {
  position: absolute;
  width: 0.625rem;
  height: 2.125px;
  top: 1.625rem;
  background: #fdd54a;
  border-radius: 1px;
  -webkit-box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.43);
          box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.43);
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
}

.sub_menu_link.active::before {
  -webkit-transform: rotate(-60deg);
          transform: rotate(-60deg);
  right: -14px;
}

.sub_menu_link.active::after {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  right: -18px;
}

.next-arrow,
.prev-arrow {
  background: url("../images/icon_arrow.webp") center center no-repeat;
  background-size: cover;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
  cursor: pointer;
}

.next-arrow:active,
.prev-arrow:active {
  background: url("../images/icon_arrow_tab.webp") center center no-repeat;
  background-size: cover;
}

.next-arrow.slick-disabled,
.prev-arrow.slick-disabled {
  display: none;
}

.next-arrow {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  right: -1.5rem;
}

@media screen and (min-width: 92rem) {
  .next-arrow {
    right: 0rem;
  }
}

.prev-arrow {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  left: -1.5rem;
}

@media screen and (min-width: 92rem) {
  .prev-arrow {
    left: 0rem;
  }
}

.banner {
  position: relative;
  width: 100%;
  min-height: 100%;
}

.banner .main_title {
  position: absolute;
  bottom: 11rem;
  width: 100%;
  z-index: 10;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.banner .main_title::before {
  top: -2rem;
  left: 0;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.banner .main_title a {
  color: #FDD54A;
  font-weight: bold;
  display: block;
}

@media screen and (max-width: 36rem) {
  .banner .main_title a {
    margin-top: 0.5rem;
  }
}

.banner .main_title h3 {
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.5rem;
}

@media screen and (max-width: 36rem) {
  .banner .main_title h3 {
    font-weight: bold;
    font-size: 1.625rem;
  }
}

@media screen and (max-width: 36rem) and (max-width: 26rem) {
  .banner .main_title h3 {
    font-size: 1.125rem;
    line-height: 1rem;
  }
}

@media screen and (max-width: 26rem) {
  .banner .main_title p {
    font-size: 0.875rem;
  }
}

.banner_bg {
  display: none;
}

@media screen and (max-width: 48rem) {
  .banner .main_title {
    width: 100%;
    top: auto;
  }
  .banner .main_title::before {
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    height: 7.5rem;
  }
  .banner video {
    display: none;
    visibility: hidden;
  }
  .banner_bg {
    display: block;
  }
}

@media screen and (max-width: 48rem) and (max-width: 48rem) {
  .banner_bg {
    width: 100%;
    height: 100vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media screen and (max-width: 48rem) and (max-width: 36rem) {
  .banner_bg {
    height: auto;
  }
}

.play_buton {
  position: absolute;
  width: 12.5rem;
  height: auto;
  right: 0;
  top: 0rem;
  z-index: 1001;
}

@media screen and (max-width: 85rem) {
  .play_buton {
    z-index: 20;
  }
}

.play_buton .play {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.play_buton .play_img {
  position: absolute;
  right: 5rem;
  -webkit-animation: wave1 4s alternate infinite;
          animation: wave1 4s alternate infinite;
}

.play_buton.anim .wheel {
  -webkit-animation: waveanim 0.4s linear infinite;
          animation: waveanim 0.4s linear infinite;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.play_buton.anim .play_game {
  -webkit-animation: bounce-in 0.5s ease-out forwards;
          animation: bounce-in 0.5s ease-out forwards;
}

.play_buton ul {
  position: absolute;
  top: 10rem;
  left: -5rem;
  text-transform: uppercase;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  width: 200px;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  background: url("../images/dropdown_play.webp") top center no-repeat;
  background-size: contain;
  z-index: -4;
}

.play_buton ul li {
  position: relative;
  text-align: center;
  left: 0;
  top: 6.25rem;
}

.play_buton ul li:not(:last-child) {
  margin-bottom: 1rem;
}

.play_buton ul a {
  font-size: 19px;
  color: #ffffff;
  font-family: "Signika", sans-serif;
  font-weight: bold;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
  text-shadow: 1px 0 0 #4c0000, -1px 0 0 #4c0000, 0 1px 0 #4c0000, 0 -1px 0 #4c0000, 1px 1px #4c0000, -1px -1px 0 #4c0000, 1px -1px 0 #4c0000, -1px 1px 0 #4c0000;
}

.play_buton ul a:hover {
  color: #fdd54a;
  text-shadow: none;
}

.play_buton ul.active {
  height: 200px;
  z-index: -1;
  visibility: visible;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  -webkit-animation: bounce-in 0.5s ease-out forwards;
          animation: bounce-in 0.5s ease-out forwards;
}

.play_buton .board {
  top: 1rem;
  right: 6.5rem;
  width: 10.5rem;
  height: 8.25rem;
  z-index: -3;
  -webkit-animation: wave1 2s alternate infinite;
          animation: wave1 2s alternate infinite;
}

.play_buton .sword {
  width: 13.5rem;
  height: 7.5rem;
  top: 4rem;
  z-index: -2;
}

.play_buton .wheel {
  top: 4rem;
  width: 12.75rem;
  height: 12.75rem;
  -webkit-animation: wave 4s alternate infinite;
          animation: wave 4s alternate infinite;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  z-index: -1;
}

.play_buton .play_game {
  top: 11rem;
  right: 5rem;
  width: 14.75rem;
  height: 4.125rem;
}

@media screen and (max-width: 85rem) {
  .play_buton {
    top: 6vh;
    width: 9rem;
    left: 50%;
    z-index: 3;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .play_buton ul {
    left: -1.5rem;
    width: 185px;
    top: 9rem;
  }
  .play_buton ul li {
    top: 5.75rem;
  }
  .play_buton .play_game {
    top: 11rem;
    left: 0;
    width: 100%;
    height: auto;
  }
  .play_buton .wheel {
    left: 0;
    top: 6rem;
    width: 100%;
    height: auto;
  }
  .play_buton .sword {
    width: 100%;
    height: auto;
    top: 6rem;
    left: 0;
  }
  .play_buton .board {
    top: 2.5rem;
    left: 0;
    width: 100%;
    height: auto;
  }
}

.slider_head {
  position: relative;
  z-index: 5;
  top: 0rem;
  background: #0d2334;
  -webkit-box-shadow: 0px -10px 60px 80px #0d2334;
          box-shadow: 0px -10px 60px 80px #0d2334;
}

@media only screen and (max-width: 48rem) {
  .slider_head {
    background-color: #0e2335;
  }
}

@media only screen and (max-width: 36rem) {
  .slider_head {
    top: -8rem;
  }
}

.slider_head--main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  position: relative;
  top: -10rem;
}

@media screen and (max-width: 36rem) {
  .slider_head--main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.slider_head .slider_item {
  position: relative;
}

.slider_head .slider_item:not(:last-child) {
  margin-right: 1.5rem;
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .slider_head .slider_item:not(:last-child) {
    margin-right: 0;
  }
}

.common_title {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 0;
  background: url("../images/common_title.webp") center center no-repeat;
  background-size: cover;
  width: 333px;
  height: 108px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2.5rem;
  color: #573b33;
  z-index: 2;
}

@media screen and (max-width: 80rem) {
  .common_title {
    width: 250px;
    height: 80px;
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 36rem) {
  .common_title {
    width: 200px;
    height: 65px;
    font-size: 1.75rem;
  }
}

.common_title span {
  margin-top: -1rem;
}

.deco_fore_01,
.deco_fore_02,
.deco_fore_03,
.deco_fore_04 {
  position: absolute;
  max-width: 80px;
  height: auto;
  z-index: 10;
}

@media screen and (max-width: 36rem) {
  .deco_fore_01,
  .deco_fore_02,
  .deco_fore_03,
  .deco_fore_04 {
    max-width: 50px;
  }
}

.world {
  background: url("../images/world.webp") no-repeat top center;
  background-size: cover;
  width: 100%;
  height: 190vh;
  position: relative;
}

.world .world_bottom {
  top: -8rem;
}

@media screen and (min-width: 92rem) {
  .world .world_bottom {
    top: -35rem;
  }
}

@media screen and (max-width: 36rem) {
  .world .world_bottom {
    margin-top: 20vh !important;
    top: 13rem;
  }
}

@media screen and (max-width: 26rem) {
  .world .world_bottom {
    top: 7rem;
  }
}

@media screen and (max-width: 23.4375rem) {
  .world .world_bottom {
    top: 13rem;
  }
}

@media screen and (max-width: 22.5rem) {
  .world .world_bottom {
    top: 11rem;
  }
}

@media screen and (max-width: 23.4375rem) {
  .world .world_bottom {
    margin-top: 0vh !important;
  }
}

.world .mounth_bg {
  width: 100vw;
}

.world .tower_bg {
  position: relative;
}

@media screen and (max-width: 36rem) {
  .world .tower_bg::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
    -webkit-box-shadow: 0px -10px 60px 80px #131824;
            box-shadow: 0px -10px 60px 80px #131824;
  }
}

.world .main_title {
  max-width: 80%;
  padding: 0 2rem;
  z-index: 1000;
}

.world .main_title_mobile {
  margin-top: 60vh;
}

@media screen and (min-width: 92rem) {
  .world .main_title_mobile {
    margin-top: 130vh;
  }
}

@media screen and (max-width: 90rem) {
  .world .main_title_mobile {
    margin-top: 60vh;
  }
}

@media screen and (max-width: 36rem) {
  .world .main_title {
    max-width: 100%;
  }
}

.world .main_title h3 {
  font-size: 2rem;
  font-weight: 500;
}

.world .main_title_top {
  z-index: 5;
  top: -5rem;
}

.world .main_title_top h3 {
  font-weight: 400;
}

.world .main_title_top::before {
  background: none;
}

.world .deco_fore_03 {
  right: 0;
  bottom: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

@media screen and (max-width: 36rem) {
  .world {
    margin-top: -10rem;
    max-height: 120vh;
  }
  .world .main_title_top {
    top: -6rem;
  }
  .world .main_title h2 {
    font-size: 3rem;
    line-height: 3rem;
  }
  .world .main_title h3 {
    font-size: 1.5rem;
  }
}

.world_mounth {
  background: url("../images/bg_battle_2.webp") no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: -15rem;
}

@media screen and (max-width: 36rem) {
  .world_mounth {
    top: -19rem;
  }
}

.world .crab {
  position: absolute;
  z-index: 1;
}

.world .crab_6,
.world .crab_5,
.world .crab_3 {
  max-width: 134px;
}

@media screen and (max-width: 36rem) {
  .world .crab_6,
  .world .crab_5,
  .world .crab_3 {
    max-width: 20%;
  }
}

.world .crab_6 {
  top: 9%;
  left: 28%;
}

@media screen and (max-width: 36rem) {
  .world .crab_6 {
    top: 12%;
    left: 20%;
  }
}

.world .crab_4 {
  top: 3%;
  right: 8%;
  max-width: 250px;
}

@media screen and (max-width: 36rem) {
  .world .crab_4 {
    max-width: 30%;
    top: 6%;
    right: -2rem;
  }
}

.world .crab_5 {
  top: 17%;
  left: 50%;
}

@media screen and (min-width: 92rem) {
  .world .crab_5 {
    top: 17%;
  }
}

@media screen and (min-width: 100rem) {
  .world .crab_5 {
    top: 16%;
  }
}

@media screen and (width: 2560px) {
  .world .crab_5 {
    top: 14%;
  }
}

@media screen and (max-width: 36rem) {
  .world .crab_5 {
    top: 13%;
    left: 48%;
    max-width: 17%;
  }
}

@media screen and (max-width: 26rem) {
  .world .crab_5 {
    top: 15%;
  }
}

@media screen and (max-width: 24.375rem) {
  .world .crab_5 {
    top: 17%;
  }
}

.world .crab_3 {
  top: 30%;
  right: 20%;
}

@media screen and (min-width: 92rem) {
  .world .crab_3 {
    top: 30%;
  }
}

@media screen and (min-width: 100rem) {
  .world .crab_3 {
    top: 28%;
  }
}

@media screen and (width: 2560px) {
  .world .crab_3 {
    top: 26%;
  }
}

@media screen and (max-width: 36rem) {
  .world .crab_3 {
    top: 33%;
  }
}

@media screen and (max-width: 26rem) {
  .world .crab_3 {
    top: 31%;
  }
}

@media screen and (max-width: 24.375rem) {
  .world .crab_3 {
    top: 33%;
  }
}

.world .crab_1 {
  top: 33%;
  left: -40%;
  max-width: 55%;
  height: auto;
}

@media screen and (min-width: 100rem) {
  .world .crab_1 {
    left: -35%;
  }
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .world .crab_1 {
    top: 70%;
    left: -110%;
    max-width: 70%;
  }
}

.world .crab_2 {
  top: 38%;
  right: -30%;
  max-width: 400px;
}

@media screen and (min-width: 100rem) {
  .world .crab_2 {
    right: -20%;
  }
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .world .crab_2 {
    top: 70%;
    right: -115%;
    max-width: 50%;
  }
}

.world .dragon {
  position: relative;
  top: 0%;
  right: -4rem;
}

@media screen and (min-width: 92rem) {
  .world .dragon img {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}

@media screen and (width: 2560px) {
  .world .dragon img {
    -webkit-transform: scale(1.7);
            transform: scale(1.7);
  }
}

@media screen and (min-width: 92rem) {
  .world .dragon {
    position: absolute;
    right: 0;
    top: 10%;
  }
}

@media screen and (max-width: 36rem) {
  .world .dragon {
    top: 0%;
    -webkit-transform: scale(1.4) translateX(10%) !important;
            transform: scale(1.4) translateX(10%) !important;
    right: -1rem;
  }
}

.world .mount {
  position: absolute;
  top: 0%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 36rem) {
  .world .mount {
    top: 10%;
  }
}

@media screen and (max-width: 23.5rem) {
  .world .mount {
    top: 8%;
  }
}

.world .seashell {
  position: relative;
  top: 4rem;
  z-index: 100;
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .world .seashell {
    top: 1rem;
    left: 1rem;
  }
}

.world .seashell img {
  position: relative;
}

.world .seashell_down img, .world .seashell_up img {
  max-width: 300px;
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .world .seashell_down img, .world .seashell_up img {
    max-width: 115px;
  }
}

.world .seashell_down {
  position: relative;
  top: -6rem;
}

.world .seashell_down::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../images/seashell_fx.webp") center center no-repeat;
  top: 9rem;
  left: -1rem;
  background-size: contain;
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
  -webkit-animation: bluranim  1.5s alternate infinite;
          animation: bluranim  1.5s alternate infinite;
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .world .seashell_down::before {
    top: 3rem;
    left: -0.5rem;
  }
}

@media screen and (max-width: 36rem) {
  .world .seashell_down {
    top: -1.5rem;
  }
}

.world .seashell_down img {
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-animation-name: wobble-top;
          animation-name: wobble-top;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.world .seashell_coin {
  position: relative;
  z-index: 200;
}

.world .seashell_coin::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../images/seashell_fx.webp") center center no-repeat;
  top: -3rem;
  left: -1rem;
  background-size: contain;
  -webkit-transform: scale(2.5);
          transform: scale(2.5);
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .world .seashell_coin::before {
    -webkit-transform: scale(3.6);
            transform: scale(3.6);
    top: -2rem;
    left: -0.5rem;
  }
}

.world .seashell_coin img {
  max-width: 200px;
  top: 5.625rem;
  left: 2.5rem;
  z-index: 1;
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .world .seashell_coin img {
    max-width: 70px;
    top: 2rem;
    left: 1.4rem;
  }
}

.world .seashell_down img {
  top: 13.5rem;
  z-index: -1;
}

@media screen and (max-width: 36rem) {
  .world .seashell_down img {
    top: 4.5rem;
  }
}

.battle {
  background: #101d30;
  -webkit-box-shadow: 0px -10px 60px 80px #111f32;
          box-shadow: 0px -10px 60px 80px #111f32;
  margin-top: 2rem;
}

@media screen and (min-width: 100rem) {
  .battle {
    margin-top: 6rem;
  }
}

@media screen and (min-width: 75rem) {
  .battle .slick-list {
    overflow: visible;
  }
}

.battle .main_title {
  max-width: 60% !important;
  padding: 0 2rem;
}

@media screen and (max-width: 48rem) {
  .battle .main_title {
    padding: 0;
    max-width: 100% !important;
  }
}

.battle .main_title h3 {
  font-size: 2rem;
  font-weight: 500;
}

.battle .main_title_bottom {
  max-width: 50%;
}

@media screen and (max-width: 48rem) {
  .battle .main_title_bottom {
    max-width: 100%;
  }
}

.battle .main_title_bottom::before {
  background: none;
}

.battle .main_title_bottom h3 {
  font-size: 2rem;
  font-weight: 500;
}

@media screen and (max-width: 60rem) {
  .battle {
    background: -webkit-gradient(linear, left top, left bottom, from(#101d30), color-stop(80%, #285c5e));
    background: linear-gradient(180deg, #101d30 0%, #285c5e 80%);
  }
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .battle {
    margin-top: 9rem;
  }
}

.battle .story_main {
  position: relative;
  padding: 6rem 2rem 2rem;
  height: 700px;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

@media screen and (min-width: 100rem) {
  .battle .story_main {
    padding: 6rem 2.5rem 2rem;
  }
}

@media screen and (max-width: 80rem) {
  .battle .story_main {
    padding: 6rem 1.5rem 2rem;
  }
}

@media screen and (max-width: 75rem) {
  .battle .story_main {
    padding: 6rem 3rem 2rem;
  }
}

@media screen and (max-width: 62rem) {
  .battle .story_main {
    padding: 6rem 2rem 2rem;
  }
}

@media screen and (max-width: 60rem) {
  .battle .story_main {
    padding: 6rem 1.5rem 2rem;
  }
}

@media screen and (max-width: 48rem) {
  .battle .story_main {
    padding: 6rem 1rem 2rem;
  }
}

@media screen and (max-width: 36rem) {
  .battle .story_main {
    padding: 10rem 1.5rem 2rem;
    height: 720px;
  }
}

@media screen and (max-width: 26rem) {
  .battle .story_main {
    height: 700px;
  }
}

@media screen and (max-width: 22.5rem) {
  .battle .story_main {
    height: 630px;
  }
}

.battle .story_main::before {
  content: "";
  position: absolute;
  background: url("../images/board_info.webp") center center no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transform: scaleX(1) scaleY(1);
          transform: scaleX(1) scaleY(1);
}

@media screen and (max-width: 36rem) {
  .battle .story_main::before {
    -webkit-transform: scaleX(1) scaleY(1.2);
            transform: scaleX(1) scaleY(1.2);
  }
}

@media screen and (max-width: 26rem) {
  .battle .story_main::before {
    -webkit-transform: scaleX(1) scaleY(1.1);
            transform: scaleX(1) scaleY(1.1);
  }
}

.battle .slider_head--list {
  margin: 5rem 0 3rem 0;
}

@media screen and (max-width: 48rem) {
  .battle .slider_head--list {
    margin: 0rem 0 -2rem 0;
  }
}

@media screen and (max-width: 36rem) {
  .battle .slider_head--list {
    margin: -2rem 0 2rem 0;
  }
}

.battle .story_contain {
  position: relative;
  top: -4rem;
  z-index: 1;
}

@media screen and (max-width: 80rem) {
  .battle .story_contain {
    top: -5rem;
  }
}

@media screen and (max-width: 48rem) {
  .battle .story_contain {
    top: -6rem;
  }
}

@media screen and (max-width: 36rem) {
  .battle .story_contain {
    top: 0rem;
  }
}

.battle .story_contain::before {
  content: "";
  position: absolute;
  background: url("../images/board_paper.webp") center center no-repeat;
  background-size: cover;
  -webkit-filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.75));
          filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.75));
  height: 450px;
  width: 100%;
  top: 4.75rem;
  left: 0;
  z-index: -1;
}

@media only screen and (max-width: 80rem) {
  .battle .story_contain::before {
    top: 6.75rem;
  }
}

@media only screen and (max-width: 48rem) {
  .battle .story_contain::before {
    background-size: contain;
    top: 5.5rem;
    height: 100%;
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1);
  }
}

@media screen and (max-width: 26rem) {
  .battle .story_contain::before {
    top: 0rem;
    -webkit-transform: scaleY(1.3);
            transform: scaleY(1.3);
  }
}

.battle .story_contain_img {
  position: relative;
}

@media screen and (max-width: 36rem) {
  .battle .story_contain_img {
    top: -2rem;
  }
}

@media screen and (max-width: 26rem) {
  .battle .story_contain_img {
    top: -2.5rem;
  }
}

@media screen and (max-width: 24rem) {
  .battle .story_contain_img {
    top: -1rem;
  }
}

.battle .story_contain_img .gamemode_mineloot_a,
.battle .story_contain_img .gamemode_adventure_a,
.battle .story_contain_img .gamemode_breed_a {
  position: absolute;
}

.battle .story_contain_img .gamemode_mineloot_a {
  right: 0;
  bottom: 1rem;
  max-width: 230px;
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .battle .story_contain_img .gamemode_mineloot_a {
    max-width: 200px;
  }
}

.battle .story_contain_img .gamemode_adventure_a {
  left: -1rem;
  bottom: 0;
  max-width: 350px;
}

.battle .story_contain_img .gamemode_breed_a {
  left: -1rem;
  bottom: 0;
  max-width: 390px;
}

@media screen and (max-width: 48rem) {
  .battle .story_contain_img .gamemode_breed_a {
    left: 0;
    max-width: 330px;
  }
}

.battle .story_contain_img img {
  padding-top: 7rem;
}

@media screen and (min-width: 90rem) {
  .battle .story_contain_img img {
    padding-top: 5rem;
  }
}

@media screen and (max-width: 48rem) {
  .battle .story_contain_img img {
    padding-top: 9rem;
  }
}

@media screen and (max-width: 36rem) {
  .battle .story_contain_img img {
    width: 100%;
    margin: 0 auto;
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 26rem) {
  .battle .story_contain_img img {
    padding-top: 1.5rem;
  }
}

@media screen and (max-width: 23.4375rem) {
  .battle .story_contain_img img {
    padding-top: 0rem;
  }
}

.battle .story_info {
  text-align: center;
  color: #573b33;
  padding: 0.625rem;
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .battle .story_info {
    padding: 1rem;
    position: relative;
    top: -1.5rem;
  }
}

.battle .story_info h3 {
  font-size: 2rem;
  line-height: 2.5rem;
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

@media screen and (max-width: 48rem) {
  .battle .story_info h3 {
    max-width: 100%;
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 36rem) {
  .battle .story_info h3 {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
}

.parts {
  position: relative;
  z-index: 1;
}

.parts::before {
  content: "";
  position: absolute;
  width: 100%;
  top: 4rem;
  -webkit-box-shadow: 0px -10px 60px 80px #131824;
          box-shadow: 0px -10px 60px 80px #131824;
}

@media screen and (max-width: 80rem) {
  .parts::before {
    top: 0;
  }
}

@media screen and (max-width: 36rem) {
  .parts::before {
    top: auto;
  }
}

@media screen and (max-width: 80rem) {
  .parts {
    background-color: #131824;
  }
}

.parts .main_title {
  margin-top: 5rem;
  padding: 0 2rem;
  max-width: 50%;
}

.parts .main_title::before {
  background: none;
}

@media screen and (max-width: 48rem) {
  .parts .main_title {
    margin-top: -3rem;
    max-width: 100%;
  }
}

.parts .main_title h3 {
  font-size: 2rem;
  font-weight: 500;
}

.parts .deco_fore_03 {
  top: -8rem;
  left: 0;
}

.parts .deco_fore_04 {
  top: 0;
  right: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.parts .deco_fore_01 {
  bottom: 0;
  right: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

@media screen and (max-width: 36rem) {
  .parts {
    margin-top: -5rem;
    background: #285c5e;
    padding-top: 5rem;
  }
}

.parts .parts_contain {
  position: relative;
}

@media screen and (max-width: 48rem) {
  .parts .parts_contain {
    top: -5rem;
  }
}

@media screen and (max-width: 36rem) {
  .parts .parts_contain {
    top: 0rem;
  }
}

.parts .parts_contain::before {
  content: "";
  position: absolute;
  top: 38rem;
  background: url("../images/book_base.webp") top center no-repeat;
  background-size: contain;
  width: 100%;
  height: 3129px;
}

@media screen and (min-width: 92rem) {
  .parts .parts_contain::before {
    top: 50rem;
  }
}

@media screen and (max-width: 48rem) {
  .parts .parts_contain::before {
    top: 21rem;
    background: url("../images/book_base_mobile.webp") top center no-repeat;
    background-size: contain !important;
  }
}

.parts .parts_contain::after {
  content: "";
  position: absolute;
  background: url("../images/bg_book.webp") bottom center no-repeat;
  background-size: cover;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  padding-top: 30rem;
}

@media screen and (max-width: 36rem) {
  .parts .parts_contain::after {
    top: -6rem;
  }
}

.parts_book {
  position: relative;
  margin: 0 auto;
  padding-top: 15rem;
}

@media screen and (min-width: 92rem) {
  .parts_book {
    padding-top: 25rem;
  }
}

@media screen and (max-width: 80rem) {
  .parts_book {
    max-width: 100%;
  }
}

.parts_book img {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  width: 70%;
  top: -8rem;
}

@media screen and (min-width: 92rem) {
  .parts_book img {
    top: -18rem;
  }
}

@media screen and (max-width: 80rem) {
  .parts_book img {
    -webkit-transform: scale(1);
            transform: scale(1);
    width: 100%;
  }
}

.parts_book::before, .parts_book::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.parts_book::after {
  background: url("../images/particle_back.webp") center center no-repeat;
  background-size: contain;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-animation: bluranim 2s alternate infinite;
          animation: bluranim 2s alternate infinite;
  top: 0;
}

.parts_book::before {
  background: url("../images/fx_back.webp") center center no-repeat;
  background-size: contain;
  -webkit-transform: scale(1.9);
          transform: scale(1.9);
  bottom: 0rem;
  pointer-events: none;
}

@media screen and (max-width: 36rem) {
  .parts_book::before {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    bottom: -1rem;
  }
}

.download {
  position: relative;
  z-index: 1;
  margin-top: 16rem;
}

@media screen and (min-width: 92rem) {
  .download {
    margin-top: 12rem;
  }
}

@media screen and (max-width: 48rem) {
  .download {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 36rem) {
  .download {
    margin-top: 4rem;
  }
}

.download .main_title {
  max-width: 50%;
  padding: 0;
}

@media screen and (max-width: 48rem) {
  .download .main_title {
    max-width: 100%;
  }
}

.download .main_title::before {
  background: none;
}

.download .main_title h3 {
  font-size: 2rem;
  font-weight: 500;
}

.download .deco_fore_02 {
  bottom: 0;
  right: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.download .deco_fore_04 {
  top: -8rem;
  left: 0;
}

@media screen and (max-width: 36rem) {
  .download {
    margin-top: 8rem;
  }
}

.download_title {
  text-align: center;
  font-size: 2.5rem;
  position: relative;
  top: -4rem;
  color: #faddb5;
}

@media screen and (max-width: 48rem) {
  .download_title {
    top: 1rem;
    margin: 3rem 0;
  }
}

.download_brand {
  position: relative;
  width: 130px;
  height: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 80rem) {
  .download_brand {
    width: 90px;
    height: 90px;
  }
}

.download_brand::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: url("../images/logo_bubble.webp") center center no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.download_brand img {
  width: 60px;
}

@media screen and (max-width: 80rem) {
  .download_brand img {
    width: 45px;
  }
}

.download_list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[5];
      grid-template-columns: repeat(5, 1fr);
  margin: 1rem auto 0 auto;
}

@media screen and (max-width: 62rem) {
  .download_list {
    padding: 0 4rem;
  }
}

@media screen and (max-width: 48rem) {
  .download_list {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    padding: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.download_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  margin: 0 2rem;
}

.download_item:hover .download_brand::before, .download_item:active .download_brand::before {
  -webkit-animation-name: wobble;
          animation-name: wobble;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@media screen and (max-width: 48rem) {
  .download_item {
    margin: 0 1rem;
    margin-bottom: 1.5rem;
  }
}

.download_item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: url("../images/download_button.webp") center center no-repeat;
  background-size: contain;
  width: 125px;
  height: 50px;
  font-weight: bold;
  color: #ffffff;
}

.download_bg {
  position: relative;
  width: 100%;
  height: 500px;
  margin: 3rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.download_bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: url("../images/download_bg.webp") center center no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media screen and (max-width: 48rem) {
  .download_bg::before {
    display: none;
  }
}

.slick-dots {
  display: none !important;
}

.roadmap {
  position: relative;
  z-index: 2;
  margin-top: -110rem;
}

@media screen and (width: 2560px) {
  .roadmap {
    margin-top: -100rem !important;
  }
}

@media screen and (min-width: 92rem) {
  .roadmap {
    margin-top: -105rem;
  }
}

.roadmap .deco_fore_04 {
  top: 30%;
  max-width: 90px;
  right: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

@media screen and (max-width: 36rem) {
  .roadmap .deco_fore_04 {
    max-width: 50px;
  }
}

.roadmap .deco_fore_02 {
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 80rem) {
  .roadmap {
    margin-top: -120rem;
  }
}

@media screen and (max-width: 64rem) {
  .roadmap {
    margin-top: -108rem;
  }
}

@media screen and (max-width: 36rem) {
  .roadmap {
    margin-top: -90rem;
  }
}

.roadmap .common_title {
  top: 5rem;
}

@media screen and (max-width: 80rem) {
  .roadmap .common_title {
    top: 6rem;
  }
}

@media screen and (max-width: 48rem) {
  .roadmap .common_title {
    top: 2rem;
  }
}

@media screen and (max-width: 36rem) {
  .roadmap .common_title {
    top: 1rem;
  }
}

.roadmap_bg {
  position: relative;
  width: 100%;
  height: 60rem;
  padding: 8rem 8rem 8px 8rem;
}

.roadmap_bg::before {
  content: "";
  position: absolute;
  background: url("../images/roadmap_bg.webp") center center no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@media screen and (max-width: 60rem) {
  .roadmap_bg::before {
    -webkit-transform: scaleY(1.2);
            transform: scaleY(1.2);
  }
}

@media screen and (max-width: 48rem) {
  .roadmap_bg::before {
    -webkit-transform: none;
            transform: none;
    background: url("../images/roadmap_bg_mobile.webp") center center no-repeat;
    background-size: contain;
    height: inherit;
  }
}

@media screen and (max-width: 80rem) {
  .roadmap_bg {
    height: 53rem;
    padding: 3.5rem 5rem 8px 5rem;
  }
}

@media screen and (max-width: 48rem) {
  .roadmap_bg {
    height: 53rem;
    padding: 3.5rem 9rem 8px 9rem;
  }
}

@media screen and (max-width: 75rem) {
  .roadmap_bg {
    height: 50rem;
  }
}

@media screen and (max-width: 42.5rem) {
  .roadmap_bg {
    padding: 4rem 6rem 8px 6rem;
  }
}

@media screen and (max-width: 36rem) {
  .roadmap_bg {
    padding: 4rem 3rem 8px 3rem;
  }
}

@media screen and (max-width: 26rem) {
  .roadmap_bg {
    padding: 4rem 1rem 8px 1rem;
    height: 38rem;
  }
}

.roadmap_item {
  position: relative;
  padding: 1.5rem;
  color: #7d4d37;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  max-width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 80rem) {
  .roadmap_item {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media screen and (max-width: 36rem) {
  .roadmap_item {
    top: -3rem;
    max-width: 100%;
  }
}

.roadmap_item h2 {
  text-align: center;
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 0.75rem;
}

.roadmap_imgboat {
  max-width: 11rem;
  margin: 0 auto;
}

@media screen and (max-width: 26rem) {
  .roadmap_imgboat {
    max-width: 8rem;
  }
}

.roadmap_img_anim {
  position: absolute;
  top: 9rem;
  right: 9rem;
  max-width: 4rem;
  -webkit-animation: wave 5s alternate infinite;
          animation: wave 5s alternate infinite;
}

@media screen and (max-width: 48rem) {
  .roadmap_img_anim {
    right: 12rem;
  }
}

@media screen and (max-width: 36rem) {
  .roadmap_img_anim {
    right: 8rem;
  }
}

@media screen and (max-width: 26rem) {
  .roadmap_img_anim {
    top: 6rem;
    right: 10rem;
  }
}

.roadmap p {
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.roadmap ul {
  list-style: disc;
  font-weight: 300;
}

.roadmap ul li:not(:last-child) {
  padding-bottom: 0.5rem;
}

.backers {
  position: relative;
  z-index: 2;
  margin: 8rem 0;
}

@media screen and (max-width: 48rem) {
  .backers {
    margin: 22rem 0 8rem 0;
  }
}

@media screen and (max-width: 36rem) {
  .backers {
    margin-top: 10rem;
  }
}

.backers .common_title {
  top: -4rem;
}

@media screen and (max-width: 48rem) {
  .backers .common_title {
    top: -16rem;
  }
}

@media screen and (max-width: 36rem) {
  .backers .common_title {
    top: -7rem;
  }
}

.backers_bg {
  width: 100%;
  padding: 4rem 15rem;
  height: 25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

@media screen and (max-width: 80rem) {
  .backers_bg {
    padding: 4rem 8rem;
  }
}

@media screen and (max-width: 75rem) {
  .backers_bg {
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 4rem 8rem;
  }
}

@media screen and (max-width: 64rem) {
  .backers_bg {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 62rem) {
  .backers_bg {
    padding: 1rem 3rem;
  }
}

@media screen and (max-width: 48rem) {
  .backers_bg {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: initial;
        flex-wrap: initial;
    padding: 10rem 0rem;
  }
}

@media screen and (max-width: 36rem) {
  .backers_bg {
    padding: 5rem 0rem;
  }
}

.backers_bg::before {
  content: "";
  position: absolute;
  background: url("../images/backer_bg.webp") center center no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transform: scaleY(1.2);
          transform: scaleY(1.2);
}

@media screen and (max-width: 64rem) {
  .backers_bg::before {
    -webkit-transform: scaleY(1.3);
            transform: scaleY(1.3);
  }
}

@media screen and (max-width: 62rem) {
  .backers_bg::before {
    -webkit-transform: scaleY(1.5);
            transform: scaleY(1.5);
  }
}

@media screen and (max-width: 48rem) {
  .backers_bg::before {
    background: url("../images/backer_bg_mobile.webp") center center no-repeat;
    height: 100%;
    width: 100%;
    background-size: contain;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: inherit;
        flex-wrap: inherit;
    z-index: -1;
    -webkit-transform: scaleX(1.5) scaleY(2.2);
            transform: scaleX(1.5) scaleY(2.2);
  }
}

@media screen and (max-width: 36rem) {
  .backers_bg::before {
    -webkit-transform: scaleX(1.2) scaleY(1.5);
            transform: scaleX(1.2) scaleY(1.5);
  }
}

@media screen and (max-width: 36rem) {
  .backers_brand:not(:last-child) {
    margin-bottom: 0rem;
  }
  .backers_brand img {
    max-width: auto;
    max-height: 4rem;
  }
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  100% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}

@keyframes wave {
  0% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  100% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}

@-webkit-keyframes waveanim {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}

@keyframes waveanim {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}

.ocean {
  position: relative;
  z-index: -1;
  margin-bottom: -2rem;
  margin-top: -100rem;
  overflow: hidden;
}

@media screen and (min-width: 22.5rem) and (max-width: 36rem) {
  .ocean {
    margin-top: -36rem;
  }
}

.ocean-back {
  background: url("../images/bg_01.webp") center center no-repeat;
  background-size: contain;
  width: 100%;
  height: 2800px;
  position: relative;
  bottom: -105rem;
}

@media screen and (min-width: 110rem) {
  .ocean-back {
    bottom: -100rem !important;
  }
}

@media screen and (min-width: 92rem) {
  .ocean-back {
    bottom: -114rem;
  }
}

@media screen and (max-width: 36rem) {
  .ocean-back {
    height: 1000px;
    bottom: -85rem;
  }
}

@media screen and (max-width: 24.375rem) {
  .ocean-back {
    bottom: -87rem;
  }
}

.ocean .cra_f {
  position: relative;
  z-index: 2;
  bottom: 0;
}

.ocean .cra_f img {
  position: absolute;
  height: auto;
}

.ocean .cra_f_1 {
  bottom: -80rem;
  right: 27rem;
  width: 400px;
}

@media screen and (min-width: 92rem) {
  .ocean .cra_f_1 {
    bottom: -95rem;
  }
}

@media screen and (max-width: 80rem) {
  .ocean .cra_f_1 {
    width: 300px;
    bottom: -84rem;
    right: 20rem;
  }
}

@media screen and (max-width: 75rem) {
  .ocean .cra_f_1 {
    width: 250px;
    bottom: -88rem;
  }
}

@media screen and (max-width: 62rem) {
  .ocean .cra_f_1 {
    width: 220px;
    right: 16rem;
    bottom: -94rem;
  }
}

@media screen and (max-width: 48rem) {
  .ocean .cra_f_1 {
    width: 180px;
    bottom: -95rem;
  }
}

@media screen and (max-width: 38rem) {
  .ocean .cra_f_1 {
    right: 9rem;
  }
}

@media screen and (max-width: 36rem) {
  .ocean .cra_f_1 {
    right: 8rem;
    bottom: -84rem;
  }
}

@media screen and (max-width: 26rem) {
  .ocean .cra_f_1 {
    right: 6rem;
    bottom: -75rem;
    width: 120px;
  }
}

@media screen and (max-width: 24.375rem) {
  .ocean .cra_f_1 {
    bottom: -74rem;
  }
}

@media screen and (max-width: 23.5rem) {
  .ocean .cra_f_1 {
    bottom: -73rem;
  }
}

@media screen and (max-width: 22.5rem) {
  .ocean .cra_f_1 {
    bottom: -71rem;
  }
}

.ocean .cra_f_2 {
  bottom: -136rem;
  right: 0rem;
  width: 500px;
  position: relative;
}

@media screen and (min-width: 92rem) {
  .ocean .cra_f_2 {
    bottom: -154rem;
  }
}

@media screen and (max-width: 80rem) {
  .ocean .cra_f_2 {
    width: 400px;
    bottom: -139rem;
  }
}

@media screen and (max-width: 75rem) {
  .ocean .cra_f_2 {
    width: 350px;
    bottom: -142rem;
  }
}

@media screen and (max-width: 62rem) {
  .ocean .cra_f_2 {
    width: 282px;
  }
}

@media screen and (max-width: 48rem) {
  .ocean .cra_f_2 {
    width: 252px;
  }
}

@media screen and (max-width: 38rem) {
  .ocean .cra_f_2 {
    bottom: 11rem;
  }
}

@media screen and (max-width: 36rem) {
  .ocean .cra_f_2 {
    bottom: -134rem;
    right: -2rem;
  }
}

@media screen and (max-width: 26rem) {
  .ocean .cra_f_2 {
    width: 180px;
    bottom: -119rem;
  }
}

@media screen and (max-width: 24.375rem) {
  .ocean .cra_f_2 {
    bottom: -117rem;
  }
}

@media screen and (max-width: 23.5rem) {
  .ocean .cra_f_2 {
    bottom: -116rem;
  }
}

@media screen and (max-width: 22.5rem) {
  .ocean .cra_f_2 {
    bottom: -113rem;
  }
}

.ocean .cra_f_3 {
  bottom: 4rem;
  left: 3rem;
  width: 616px;
  display: inline-block;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-animation-name: wobble-top;
          animation-name: wobble-top;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@media screen and (max-width: 80rem) {
  .ocean .cra_f_3 {
    width: 500px;
  }
}

@media screen and (max-width: 75rem) {
  .ocean .cra_f_3 {
    width: 450px;
  }
}

@media screen and (max-width: 62rem) {
  .ocean .cra_f_3 {
    width: 350px;
  }
}

@media screen and (max-width: 48rem) {
  .ocean .cra_f_3 {
    width: 300px;
  }
}

@media screen and (max-width: 38rem) {
  .ocean .cra_f_3 {
    width: 280px;
  }
}

@media screen and (max-width: 36rem) {
  .ocean .cra_f_3 {
    left: 1rem;
    width: 250px;
  }
}

@media screen and (max-width: 26rem) {
  .ocean .cra_f_3 {
    width: 160px;
  }
}

.ocean-front {
  background: url("../images/bg_03.webp") bottom center no-repeat;
  background-size: contain;
  width: 100%;
  height: 2666px;
  position: absolute;
  bottom: -212rem;
}

@media screen and (width: 2560px) {
  .ocean-front {
    background-size: cover;
    bottom: -267rem !important;
  }
}

@media screen and (min-width: 92rem) {
  .ocean-front {
    bottom: -234rem;
  }
}

@media screen and (max-width: 75rem) {
  .ocean-front {
    bottom: -201rem;
  }
}

@media screen and (max-width: 64rem) {
  .ocean-front {
    bottom: -204rem;
  }
}

@media screen and (max-width: 62rem) {
  .ocean-front {
    bottom: -200rem;
  }
}

@media screen and (max-width: 48rem) {
  .ocean-front {
    bottom: -206rem;
  }
}

@media screen and (max-width: 36rem) {
  .ocean-front {
    bottom: -191rem;
  }
}

@media screen and (max-width: 26rem) {
  .ocean-front {
    bottom: -165rem;
  }
}

@media screen and (max-width: 24.375rem) {
  .ocean-front {
    bottom: -162rem;
  }
}

@media screen and (max-width: 23.4375rem) {
  .ocean-front {
    bottom: -160rem;
  }
}

@media screen and (max-width: 22.5rem) {
  .ocean-front {
    bottom: -156rem;
  }
}

.ocean_footer_right {
  right: 0;
  width: 416px;
}

@media screen and (max-width: 75rem) {
  .ocean_footer_right {
    width: 350px;
  }
}

@media screen and (max-width: 62rem) {
  .ocean_footer_right {
    width: 250px;
  }
}

@media screen and (max-width: 36rem) {
  .ocean_footer_right {
    width: 180px;
  }
}

@media screen and (max-width: 26rem) {
  .ocean_footer_right {
    width: 140px;
  }
}

.ocean_footer_left {
  left: 0;
  width: 267px;
}

@media screen and (max-width: 75rem) {
  .ocean_footer_left {
    width: 230px;
  }
}

@media screen and (max-width: 62rem) {
  .ocean_footer_left {
    width: 180px;
  }
}

@media screen and (max-width: 36rem) {
  .ocean_footer_left {
    width: 150px;
  }
}

@media screen and (max-width: 26rem) {
  .ocean_footer_left {
    width: 100px;
  }
}

.ocean_footer_left, .ocean_footer_right {
  position: absolute;
  z-index: 1;
  bottom: 0;
  height: auto;
}

@media screen and (max-width: 36rem) {
  .ocean_footer_left, .ocean_footer_right {
    bottom: 1.5rem;
  }
}

.train {
  position: relative;
  width: 100vw;
  top: 0;
  left: 0;
  height: 2666px;
  z-index: 1;
}

.train .deco_fore_03 {
  left: 0;
  top: 0;
}

@media screen and (max-width: 36rem) {
  .train .deco_fore_03 {
    top: 8rem;
  }
}

@media screen and (max-width: 36rem) {
  .train {
    height: 140rem;
  }
}

.train::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 45vh;
  left: 0;
  background: url("../images/bg_train_mobile.webp") top center no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

@media screen and (width: 2560px) {
  .train::before {
    top: 33vh;
  }
}

@media screen and (min-width: 48rem) {
  .train::before {
    background: url("../images/bg_train_pc.webp") top center no-repeat;
    background-size: contain;
  }
}

@media screen and (min-width: 120rem) {
  .train::before {
    background-size: cover;
    max-height: 200%;
  }
}

@media screen and (max-width: 80rem) {
  .train::before {
    top: 35vh;
  }
}

@media screen and (max-width: 62rem) {
  .train::before {
    top: 27vh;
  }
}

@media screen and (max-width: 48rem) {
  .train::before {
    top: 20vh;
  }
}

@media screen and (max-width: 36rem) {
  .train::before {
    top: 14vh;
  }
}

.train_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -3rem;
}

@media screen and (width: 2560px) {
  .train_list {
    margin-top: -2rem;
  }
}

@media screen and (max-width: 62rem) {
  .train_list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 3.5rem;
  }
}

@media screen and (min-width: 120rem) {
  .train_img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .train_img img {
    margin-top: 10rem;
  }
}

.train_item {
  text-align: center;
}

@media screen and (max-width: 62rem) {
  .train_item {
    max-width: 60%;
    margin: 0 auto;
  }
  .train_item:not(:last-child) {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 36rem) {
  .train_item {
    max-width: 100%;
  }
}

.train_item h3 {
  font-size: 2rem;
}

.train_item h4 {
  padding-bottom: 1rem;
}

.train_item p {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media screen and (max-width: 48rem) {
  .train_item p {
    padding: 0;
  }
}

.lightfish {
  width: 300px;
  height: auto;
  position: absolute;
  left: 0;
  top: 60%;
  z-index: 1;
  -o-object-fit: contain;
     object-fit: contain;
}

@media screen and (max-width: 36rem) {
  .lightfish {
    width: 150px;
  }
}

footer {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 1.5rem 0;
  bottom: 0;
  z-index: 200;
}

footer::before {
  content: "";
  position: absolute;
  background: url("../images/bar_bottom.webp") center center no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transform: scaleX(2.1);
          transform: scaleX(2.1);
}

@media screen and (max-width: 62rem) {
  footer::before {
    background-size: cover;
  }
}

@media screen and (max-width: 36rem) {
  footer {
    padding: 1rem 0;
    height: 70px;
  }
}

footer .footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

footer .footer_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

@media screen and (max-width: 36rem) {
  footer .footer_info .copyright {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10px;
    font-size: 0.75rem;
    text-align: center;
    font-weight: 500;
  }
}

footer .footer_info img {
  max-width: 14.25rem;
  margin-right: 2rem;
}

@media screen and (max-width: 36rem) {
  footer .footer_info img {
    max-width: 5rem;
  }
}

footer ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 48rem) {
  footer ul {
    position: absolute;
    bottom: 100%;
    margin-bottom: 0.875rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

footer ul li:not(:last-child) {
  margin-right: 0.5rem;
}

footer ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 8px;
  position: relative;
  width: 4rem;
  height: 4rem;
}

@media screen and (max-width: 80rem) {
  footer ul li a {
    width: 3rem;
    height: 3rem;
  }
}

footer ul li a::before {
  content: "";
  position: absolute;
  background: url("../images/scale_button.webp") center center no-repeat;
  background-size: contain;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 36rem) {
  footer ul li a {
    padding: 0.5rem;
  }
}

footer ul li a img {
  width: 1.5rem;
  height: auto;
  -webkit-filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
}

@-webkit-keyframes wave1 {
  0% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }
}

@keyframes wave1 {
  0% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }
}

@-webkit-keyframes wobble {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}

@keyframes wobble {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}

@-webkit-keyframes bounce-in {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  20% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  40% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  60% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.96);
            transform: scale(0.96);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes bounce-in {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  20% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  40% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  60% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.96);
            transform: scale(0.96);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes wobble-top {
  16.65% {
    -webkit-transform: skew(0deg);
            transform: skew(0deg);
  }
  33.3% {
    -webkit-transform: skew(1deg);
            transform: skew(1deg);
  }
  49.95% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg);
  }
  66.6% {
    -webkit-transform: skew(0deg);
            transform: skew(0deg);
  }
  83.25% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg);
  }
  100% {
    -webkit-transform: skew(0);
            transform: skew(0);
  }
}

@keyframes wobble-top {
  16.65% {
    -webkit-transform: skew(0deg);
            transform: skew(0deg);
  }
  33.3% {
    -webkit-transform: skew(1deg);
            transform: skew(1deg);
  }
  49.95% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg);
  }
  66.6% {
    -webkit-transform: skew(0deg);
            transform: skew(0deg);
  }
  83.25% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg);
  }
  100% {
    -webkit-transform: skew(0);
            transform: skew(0);
  }
}

@-webkit-keyframes bluranim {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bluranim {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.items-center a {
  font-size: 0.875rem;
  font-weight: bold;
  white-space: nowrap;
}

@media screen and (max-width: 36rem) {
  .items-center a {
    font-size: 0.75rem;
    font-weight: bold;
  }
}
