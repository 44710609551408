@import "../page-bounty/bounty.scss";

@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --color-orange: #fc4017;
}

.leaderboard {
  font-family: "Candara", Arial, sans-serif;
  line-height: 1.2;
  position: relative;

  .active-ranking {
    background-color: #a14341 !important;
    color: #ffc300 !important;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: -10px;
    left: 0;
    right: 0;
    z-index: -1;
    background: linear-gradient(180deg, #f3d7b1 24.63%, #ecc795 100%);
  }

  &-banner {
    background: no-repeat center center/cover;

    &__logo {
      &--shiny {
        animation: rotate 22s linear infinite;

        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }

      &--glow {
        margin-left: -10px;
        transform: scale(2);
        animation: opacity 3s linear infinite;

        @keyframes opacity {
          0% {
            opacity: 1;
          }

          50% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }
      }
    }
  }

  &-content {
    display: flex;
  }

  &-tab {
    max-width: 1224px;

    &__header {
      .item {
        &.active {
          color: var(--color-orange);
        }
      }
    }

    &__linear {
      border: none;
      height: 2px;
      width: 100%;
      background: linear-gradient(to right,
          rgba(182, 129, 89, 0.3) 0%,
          rgba(182, 129, 89, 0.5) 50%,
          rgba(182, 129, 89, 0.3) 100%);
    }
  }

  &-board {
    background: #efcda0;
    border: 1px solid #d7b07d;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
}

.arena {
  .title {
    font-size: 40px;
    font-weight: 700;
  }

  .sub {
    font-weight: 400;
    font-size: 16px;
    opacity: 0.5;
  }

  input {
    &[type="search"] {
      background: rgba(250, 229, 190, 0.1);
      border: 2px solid #8d4247;
      box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.5),
        2px 2px 2px rgba(0, 0, 0, 0.5);
      outline: none !important;
      font-size: 16px !important;

      &::placeholder {
        font-size: 16px !important;
        letter-spacing: 0.05em;
        color: #3e1b29;
        opacity: 0.3;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
    }
  }

  &-list {

    .header {
      font-weight: 700;
      color: #8d4247;
    }

    .row {
      // gap: 40px;

      &>div {

        // flex: 1 1;

        &:nth-child(1) {
          width: 60px;
        }

        &:nth-child(2) {
          flex: auto;
        }

        &:nth-child(3) {
          // width: 115px;
        }
      }

      .user {
        &-info {
          &__avatar {
            // overflow: hidden;
            width: 62px;
            height: 62px;

            &-img {
              width: 58px;
              height: 58px;
              object-fit: cover;
              display: block;
              border-radius: 8px;
              position: relative;
            }
          }
        }

      }

      .crab {
        &-info {
          &__img {
            width: 58px;
            height: 58px;
            object-fit: cover;
            display: block;
            position: absolute;
            transform: scale(1.25);
            right: -10px;
          }

          &__class {
            width: 24px;
            height: 24px;
          }

          &__lv {
            -webkit-text-stroke-width: 0.5px;
            -webkit-text-stroke-color: rgba(0, 0, 0, 0.7);
          }
        }

        .item {
          width: 62px;
          height: 62px;
          position: relative;
          // overflow: hidden;
          border: 2px solid #5dedff;
          filter: drop-shadow(-1px 1px 1px #0d74ab) drop-shadow(1px 1px 1px #0d74ab);
          border-radius: 4px;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: url(../../../assets/icons/star.png);
          }
        }
      }

      .value {
        font-weight: 700;

        .user-name {
          // background: linear-gradient(180deg, #C3600F 6.25%, #F5A653 22.47%, #F1FF4D 100%);
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          // background-clip: text;
          // text-fill-color: transparent;
          // -webkit-text-stroke-width: 0.75px;
          // -webkit-text-stroke-color: #000000;
        }

        .data {
          &>div {
            &:last-child {
              &>span {
                background-color: #4295E4;
                color: #FFFFFF;
                border-radius: 12px;
                padding: 0 12px;
              }
            }
          }
        }
      }
    }


    [class*="scroll-"] {
      &>.each {
        position: relative;

        &:nth-child(even) {
          background-color: transparent;
        }

        &:nth-child(odd) {
          background-color: rgba(226, 189, 140, 1);
        }

        &.pt-0 {
          &::after {
            content: none;
          }
        }

        &.result {
          background-color: rgba(161, 67, 65, 1);
          color: rgba(255, 195, 0, 1);
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    &-list {

      .row {
        &>div {
          width: unset !important;
        }
      }

      &>.row {
        &>div {
          width: unset !important;
          justify-content: space-between !important;
        }
      }
    }
  }
}

.bounties {
  @extend .arena;

  &-list {
    .header {
      font-weight: 700;
    }

    &__linear {
      height: 2px;
      background: linear-gradient(to right,
          rgba(106, 74, 86, 0) 0%,
          rgba(106, 74, 86, 1) 50%,
          rgba(106, 74, 86, 0) 100%);
    }

    &>.row {
      gap: 40px;

      &>div {
        flex: 1 1;

        &:nth-child(4),
        &:nth-child(5) {
          justify-content: end;
          text-align: right;
        }

        &:nth-child(1) {
          flex: unset;
          width: 32px;
        }

        &:nth-child(2) {
          flex: unset;
          width: 300px;
        }

        &:nth-child(3) {
          flex: unset;
          width: 250px;
        }
      }

      .value {
        font-weight: 700;
      }

      &.each {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          top: 0;
          left: 0;
          right: 0;
          background: linear-gradient(to right,
              rgba(182, 129, 89, 0.3) 0%,
              rgba(182, 129, 89, 0.5) 50%,
              rgba(182, 129, 89, 0.3) 100%);
        }

        &.pt-0 {
          &::after {
            content: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    &-list {
      &>.row {
        &>div {
          max-width: unset !important;
          justify-content: space-between !important;
        }
      }
    }
  }
}

.reward {
  @extend .bounties;

  &-list {
    // min-height: 90vh;
    height: 50vh;
    overflow: hidden;
    overflow-y: auto;

    .header {
      font-weight: 700;
    }

    &__linear {
      height: 2px;
      background: linear-gradient(to right,
          rgba(106, 74, 86, 0) 0%,
          rgba(106, 74, 86, 1) 50%,
          rgba(106, 74, 86, 0) 100%);
    }

    &>.row {
      // gap: 40px;

      &>div {
        // flex: 1 1;
      }

      .value {
        font-weight: 700;
      }

      &.each {
        position: relative;

        &:nth-child(odd) {
          background-color: transparent;
        }

        &:nth-child(even) {
          background: linear-gradient(to right,
              rgba(237, 213, 167, 0) 0%,
              rgba(237, 213, 167, 1) 50%,
              rgba(237, 213, 167, 0) 100%);
        }

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          top: 0;
          left: 0;
          right: 0;
          background: linear-gradient(to right,
              rgba(182, 129, 89, 0.3) 0%,
              rgba(182, 129, 89, 0.5) 50%,
              rgba(182, 129, 89, 0.3) 100%);
        }

        &.top {
          &::after {
            content: none;
          }
        }
      }
    }

    &__total {
      font-family: "Prompt", sans-serif;
      font-weight: 700;
      font-size: 12px;
      color: #862a2a;
    }

    &__nav {
      .btn-nav {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none !important;
        cursor: pointer;
        background: linear-gradient(180deg, #f76140 0%, #db401e 100%);
        border: 1px solid #ffac76;
        border-radius: 8px;
        filter: drop-shadow(0px 3px 10px rgba(163, 49, 0, 0.25));
      }
    }
  }

  @media screen and (max-width: 767px) {
    &-list {
      min-height: calc(90vh);
      overflow: hidden;
      overflow-y: auto;

      &>.row {
        &>div {
          max-width: unset !important;
          justify-content: space-between !important;
        }
      }
    }
  }
}