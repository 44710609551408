$rootWidth: 700px;
$rootHeight: 800px;

$imgWidth: 320px;
$imgHeight: 240px;

.modal-main {
  &.leaderboard {
    width: 90vw;
    max-width: 1024px;
    height: 90vh;
    overflow: hidden;
    background: radial-gradient(50% 50% at 50% 50%, #EFD8AA 0%, #CAA67B 100%);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  }
}